
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Cart from './components/Cart'
import AuthDrawer from './components/AuthDrawer'
import Footer from './components/Footer'
import NavBar from './components/NavBar'
import Home from "./pages/Home"
import ProductPage from './pages/productPage/ProductPage'
import { Box } from '@chakra-ui/react'
import ProductsGrid from './pages/ProductsGrid'
import ScrollToTop from './hooks/ScrollToTop'
import Faq from './pages/faq/Faq'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import Contact from './pages/Contact'
import Blog from './pages/Blog'
import { register } from 'swiper/element/bundle'
import BlogPost from './pages/BlogPost/BlogPost'
import BuyingGuide from './pages/BuyingGuide'
register();

function App() {

  return (
      <Box w='100%' minHeight='100vh' position="relative" className="App" paddingTop='5rem' paddingBottom={['60rem', '55rem', '50rem', '35rem']}>
        <Router basename="/">
          <ScrollToTop>
            <NavBar />
            <Cart />
            <AuthDrawer />
            <Switch>
              <Route exact path='/collections/:collectionHandle'>
                <ProductsGrid />
              </Route>
              <Route exact path='/brand/:brandHandle'>
                <ProductsGrid />
              </Route>
              <Route exact path="/products/:handle">
                <ProductPage />
              </Route>
              <Route exact path='/faq'>
                <Faq />
              </Route>
              <Route exact path='/privacy-policy'>
                <PrivacyPolicy />
              </Route>
              <Route exact path='/terms-and-conditions'>
                <TermsAndConditions />
              </Route>
              <Route exact path='/contact'>
                <Contact />
              </Route>
              <Route exact path='/blog'>
                <Blog />
              </Route>
              <Route exact path='/blog/post/:blogPostHandle'>
                <BlogPost />
              </Route>
              <Route exact path='/buying-guide'>
                <BuyingGuide />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
            </Switch>
            <Footer />
          </ScrollToTop>
        </Router>
      </Box>
  );
}

export default App;
