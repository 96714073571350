import React, { useContext } from 'react'
import { Grid, GridItem, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { ShopContext } from '../../context/ShopProvider'
import { Link } from 'react-router-dom'
import './shopMenu.scss'

const ShopMenu = () => {
    const { collections } = useContext(ShopContext)

    return (
        <Menu>
            <MenuButton
                color='white'
                fontWeight='bold'
                fontSize='1.25rem'>
                Shop
            </MenuButton>
            <MenuList minWidth='700px' p='1rem'>
                <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                    {collections.map((collection, index) => 
                        <GridItem key={collection + index}>
                            <MenuItem>                   
                                <Link className='shop-menu-link' to={`/collections/${collection.handle}`}>{collection.title}</Link>
                            </MenuItem>             
                        </GridItem>)
                    }
                    <GridItem>
                        <MenuItem>                   
                            <Link className='shop-menu-link' to={`/collections/all`}>Shop All Ebikes</Link>
                        </MenuItem>             
                    </GridItem>
                </Grid>  
            </MenuList>
        </Menu>
    )
}

export default ShopMenu
