import React, { useState} from 'react'
import { Heading, Text, Grid, Box, GridItem, Icon, FormControl, Input, Textarea, Button } from '@chakra-ui/react'
import { BsChatDots } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'
import { FiPhoneCall } from 'react-icons/fi'
import { BiMailSend } from 'react-icons/bi'

const Contact = () => {
    return (
        <Box mt='2rem' px={['1rem', '2rem']} maxWidth='650px' mx='auto'>
            <Heading textAlign='center' mb='1rem'>Contact Us</Heading>
            <Text textAlign='center' maxWidth='650px' mx='auto' fontFamily='montserrat' mb='4rem'>If you have any questions, comments, our concerns please let us know. We are open to take phone calls Mon - Sat, 8AM - 8PM CST, and Sun 10AM - 4PM CST. Email support is available 24/7. Live chat support is also available.</Text>
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}>
                <GridItem display='flex' px={[0, '0.5rem']} mb='2rem'>
                    <Box pr={['0.5rem', '2rem']}>
                        <Icon as={BsChatDots} w={['25px', '50px']} h={['25px', '50px']}></Icon>
                    </Box>
                    <Box>
                        <Text>Live Chat:</Text>
                        <Text fontFamily='montserrat'>Click on the Bottom Right</Text>
                    </Box>
                </GridItem>
                <GridItem display='flex' px={[0, '0.5rem']} mb='2rem'>
                    <Box pr={['0.5rem', '2rem']}>
                        <Icon as={BiMailSend} w={['25px', '50px']} h={['25px', '50px']}></Icon>
                    </Box>
                    <Box>
                        <Text>Email Support:</Text>
                        <Text fontFamily='montserrat'>support@dandyhorsebikes.com</Text>
                    </Box>
                </GridItem>
                <GridItem display='flex' px={[0, '0.5rem']} mb='2rem'>
                    <Box pr={['0.5rem', '2rem']}>
                        <Icon as={FiPhoneCall} w={['25px', '50px']} h={['25px', '50px']}></Icon>
                    </Box>
                    <Box>
                        <Text>Phone Support:</Text>
                        <Text fontFamily='montserrat'>(888) 420-0112</Text>
                    </Box>
                </GridItem>
                <GridItem display='flex' px={[0, '0.5rem']} mb='2rem'>
                    <Box pr={['0.5rem', '2rem']}>
                        <Icon as={AiOutlineMail} w={['25px', '50px']} h={['25px', '50px']}></Icon>
                    </Box>
                    <Box>
                        <Text>Mailing Address:</Text>
                        <Text fontFamily='montserrat'>8101 Boat Club Rd</Text>
                        <Text fontFamily='montserrat'>Ste 240 #659</Text>
                        <Text fontFamily='montserrat'>Fort Worth, TX 76179</Text>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    )
}

export default Contact
