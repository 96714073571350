import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Link as ExternalLink} from '@chakra-ui/react'
import { Grid, Box, Image, VStack, GridItem, Heading, Text, Icon, HStack } from '@chakra-ui/react'
import { FaInstagram, FaPinterestP } from 'react-icons/fa'
import { RiCopyrightLine, RiFacebookFill } from 'react-icons/ri'
import { FiTwitter, FiYoutube } from 'react-icons/fi'
import ShopByType from './ShopByType'
import { ShopContext } from '../context/ShopProvider'

const Footer = () => {
    const { openCart, checkout } = useContext(ShopContext)

    return (
        <Box position='absolute' bottom='0' backgroundColor="black" width="100%" py='2rem'>
            <Grid templateColumns={["repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"]} px={['1rem', '1rem', '5rem', '5rem']}>
                <GridItem colSpan={[2, 3, 3, 1]} mb={['2rem', '2rem', '2rem', 0]}>
                    <Image width='230px' mx='auto' src="https://cdn.shopify.com/s/files/1/0556/6421/4170/files/new_transparent_logo_480x480.png?v=1617307594" />
                </GridItem>
                <GridItem fontSize={['14px', '1rem', '1rem', '1rem']} colSpan={[1, 1, 1, 1]} color='white' textAlign='center'>
                    <Text>8101 Boat Club Rd</Text>
                    <Text>Ste 240 #659</Text>
                    <Text>Fort Worth, TX 76179</Text>
                </GridItem>
                <GridItem fontSize={['14px', '1rem', '1rem', '1rem']} colSpan={[1, 1, 1, 1]} colStart={[2, 3, 2, 3]} color='white' textAlign='center'>
                    <Text>Hours</Text>
                    <Text>Mon - Sat | 8AM - 8PM CST</Text>
                    <Text>Sun | 10AM - 4PM CST</Text>
                </GridItem>
                <GridItem colSpan={[2, 3, 1, 1]} my={['2rem', '2rem', 0, 0]} px={['1rem', '3rem', 0, 0]} color='white' textAlign='center' display='flex' flexDirection='column'>
                    <HStack justifyContent='space-between' px='1rem' >
                        <Icon as={FaInstagram} w='25px' h='25px' />
                        <Icon as={RiFacebookFill} w='25px' h='25px' />
                        <Icon as={FaPinterestP} w='25px' h='25px' />
                        <Icon as={FiTwitter} w='25px' h='25px' />
                        <Icon as={FiYoutube} w='25px' h='25px' />
                    </HStack>
                </GridItem>
                <GridItem>
                    <VStack pt="2rem" color="white">
                        <Heading>Shop</Heading>
                        <Link to="/collections/all">Shop All E-Bikes</Link>
                        <ShopByType menuType='collections' />
                        <ShopByType menuType='brands' />
                        <Link to="/collections/urban-ebikes">Commuter E-Bikes</Link>
                        <Link to="/collections/cruiser-ebikes">Cruiser E-Bikes</Link>
                        <Link to="/collections/sports-ebikes">Sports E-Bikes</Link>
                        <Link to="/collections/accessories">Accessories</Link>
                    </VStack>
                </GridItem>

                <GridItem>
                    <VStack pt="2rem" color="white">
                        <Heading>Help</Heading>
                        <Link to="/">Homepage</Link>
                        {
                            checkout?.lineItems?.length > 0
                            ? <ExternalLink href={`http://shop.dandyhorsebikes.com/55664214170/checkouts/${checkout?.webUrl?.split('checkouts/')[1]}`}>Checkout</ExternalLink>
                            : <Text cursor='pointer' onClick={() => openCart()}>Checkout</Text>
                        }
                        <Link to="/faq">FAQ</Link>
                        <Link to="/">Shipping</Link>
                        <Link to="/">Warranties</Link>
                        <Link to="/terms-and-conditions">Terms & Conditions</Link>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </VStack>
                </GridItem>

                <GridItem>
                    <VStack pt="2rem" color="white">
                        <Heading>Info</Heading>
                        <Link to="/contact">Contact</Link>
                        <Link to="/blog">Blog</Link>
                        <Link to="/">Press</Link>
                    </VStack>
                </GridItem>

                <GridItem color='white' colSpan={[1, 3, 3, 1 ]} textAlign='center' mt='2rem' mb='4rem'>
                    <Heading>Bicycles Redefined for the Future</Heading>
                </GridItem>
            </Grid>
            <Text color='white' textAlign='center' mt='3rem'><Icon as={RiCopyrightLine} /> Copyright 2021 Dandy Horse LLC</Text>
        </Box>
    )
}

export default Footer
