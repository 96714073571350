import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Badge, Text, Image, useMediaQuery } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Pagination, Navigation } from 'swiper/modules';

import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import './solutionCollections.scss'

const SolutionCollections = ({products, categoryDetails}) => {
    const [isMobile] = useMediaQuery("(max-width: 767px)")
  return (
    <Box mx='1rem' my='4rem' mb='6rem'>
        <Text w='90%' textAlign='center' mx='auto' py='2rem' fontSize={['1rem', '1.5rem']} fontFamily='Montserrat'>{categoryDetails.description}</Text>
        <Swiper
            // effect={'flip'}
            grabCursor={true}
            pagination={true}
            slidesPerView={isMobile ? 1 : 3}
            // spaceBetween={30}
            navigation={true}
            // modules={[EffectFlip, Pagination, Navigation]}
            modules={[Pagination, Navigation]}
            loop={true}
            className='solutionCollectionsProductSwiper'>
            {products.map(product => {
                const savingsCalc = 100 - ((product?.variants[0]?.price?.amount / product?.variants[0]?.compareAtPrice?.amount) * 100).toFixed(0)
                const savings = savingsCalc > 0 ? `${savingsCalc}%` : '';
                return (
                    <SwiperSlide>
                            <Link  to={`/products/${product?.handle}`} >
                                <Badge variant='outline' colorScheme='red' mx='auto' ml='1rem'>{savings && `Limited Time,  ${savings} Off`}</Badge>
                                <Box >
                                    <Image src={product.images[0].src} w="80%" mx='auto'/>
                                </Box>
                                <Text align='center'>{product.highlights}</Text>
                                <Text align='center' fontSize='2xl' mb='2rem'>{product.title}</Text>
                            </Link>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    </Box>
  )
}

export default SolutionCollections