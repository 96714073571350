import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Box, Grid, Text, Image, Heading, GridItem } from '@chakra-ui/react'
import { ShopContext } from '../context/ShopProvider'
import FeaturedCollections from '../components/FeaturedCollections'
import homePageData from '../data/homePageData'

const ProductsGrid = () => {

    const [displayedProducts, setDisplayedProducts] = useState([])

    const { collectionHandle, brandHandle } = useParams();

    const { fetchCollection, collection, products, collections } = useContext(ShopContext)

    useEffect(() => {
        setDisplayedProducts([]);
        fetchCollection(collectionHandle)
        if (collectionHandle) {
            if (collectionHandle === 'all') {
                return setDisplayedProducts(products?.filter(product => product.productType === 'E-Bike'))
            }
            return setDisplayedProducts(collection?.products);
        }

        if (brandHandle) {
            const brandFilteredProducts = products?.filter(product => product.vendor === brandHandle)
            return setDisplayedProducts(brandFilteredProducts)
        }
    }, [collectionHandle, brandHandle, products, collection])

    if (!brandHandle && !displayedProducts?.length) {
        return (
            <Box>
                <Heading textTransform='capitalize' textAlign='center' mt='2rem'>{`${collectionHandle.split('-').join(' ')} coming soon!`}</Heading>
                <Text px='3rem' textAlign='center'>In the meantime, take a look at some of our popular categories below...</Text>
                <FeaturedCollections featuredCollectionData={homePageData.featuredCollectionsData}/>
            </Box>
        )

    } 

    return (
        <>
            {
                !brandHandle
                ? <><Heading textAlign='center' mt='1rem'>{collectionHandle != 'all' ? collection?.title : 'All E-Bikes'}</Heading><Text px='2rem' textAlign='center'>{collectionHandle != 'all' ? collection?.description : 'Browse Our E-Bike Selection'}</Text></>
                : <Heading textAlign='center' mt='1rem'>{brandHandle}</Heading>
            }
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]} maxWidth='1200px' mx='auto'>
            {
                displayedProducts?.map(product => (
                    <GridItem key={product?.id} display='flex' alignItems='center' justifyContent='center' position='relative' maxWidth='500px' mx='auto' minHeight={['420px', '500px', '500px', '500px']}>
                        <Link  to={`/products/${product?.handle}`} >
                            <Box _hover={{ opacity: '80%' }} textAlign='center' >
                                <Image src={product?.images[0].src} maxHeight='400px'/>
                                <Box position='absolute' bottom='0' width='100%'>
                                    <Text>{product?.title}</Text>
                                    <Text fontFamily='montserrat'>{`$ ${parseInt(product?.variants[0]?.price?.amount)?.toFixed(0)}`}</Text>
                                </Box>
                            </Box>
                        </Link>
                    </GridItem>
                ))
            }
            </Grid>
        </>                                                                                                                                                                                                                                                                                             
    )
}

export default ProductsGrid
