const buyingGuideData = {
    servicesData: [
        {
            imagePath: '/images/bike.png',
            title: 'Role',
            description: 'Different e-bikes are tailored to suit specific uses. For example, road e-bikes deliver speed and efficiency for paved routes, whereas electric mountain bikes are designed to conquer tough, off-road challenges. Consider your main riding activities and select an e-bike that fulfills that aim.',
            buyGuideData: true,
            path: 'blog/post/top-5-considerations-for-understanding-your-purpose-in-using-an-e-bike'
        },
        {
            imagePath: '/images/customer_service.png',
            title: 'Dimensions & Comfort',
            description: 'Ensure your e-bike\'s structure suits you perfectly. Measure your height and inseam to determine the correct frame size, and test ride the bike while adjusting the seat and handlebars for optimal comfort and safety.',
            buyGuideData: true,
            path: '/blog'
        },
        {
            imagePath: '/images/helmet_icon.png',
            title: 'Parts & Features',
            description: 'Look into the e-bike’s hardware, such as the frame, wheels, and braking mechanisms. Premium bikes usually boast higher-grade hardware for a better riding experience, but expect to pay more for these improvements.',
            buyGuideData: true,
            path: '/blog'
        },
        {
            imagePath: '/images/helmet_icon.png',
            title: 'Budget',
            description: 'Take your price range into account when choosing an e-bike. While options exist for nearly every budget, lower-cost bikes often feature less reliable components. Higher-priced models, on the other hand, tend to be more durable and of superior quality.',
            buyGuideData: true,
            path: '/blog'
        }
    ]
}

export default buyingGuideData;