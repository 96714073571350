import React from 'react'
import { Grid, Box, Image, Text, Flex} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const FeaturedCollections = ({featuredCollectionData}) => {
    return (
        <Box>
        <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"]} mt='4rem' mb={[0, '2rem', '4rem']}>
            {
                featuredCollectionData.map((collection, index) => {
                    const marginBottomSetting = index === featuredCollectionData.length - 1 ? '0' : '3rem' 
                    return (
                        <Flex mb={[marginBottomSetting, marginBottomSetting, '0']} key={collection + index} alignItems='center' justifyContent='center'>
                            <Link to={`/collections/${collection.handle}`}>
                                <Box _hover={{ opacity: '80%' }} textAlign='center'>
                                    <Image mx='auto' w='100px'src={collection.imagePath} />
                                    <Text fontWeight='bold' fontSize='24px' mb='0.5rem'>View {collection.title}</Text>
                                    <Text>{collection.description}</Text>
                                </Box>
                            </Link>
                        </Flex>
                    )
                }) 
            }
        </Grid>
    </Box> 
    )
}

export default FeaturedCollections
