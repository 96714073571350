import React, { useContext } from 'react'
import { ShopContext } from '../context/ShopProvider'
import { 
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Grid,
    Text,
    Flex,
    Image,
    Link,
    Box
} from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'

const Cart = () => {

    const { isCartOpen, closeCart, checkout, removeLineItem } = useContext(ShopContext)
    return (
        <>
            <Drawer
            isOpen={isCartOpen}
            placement="right"
            onClose={closeCart}
            size="sm"
            >
                <DrawerOverlay>
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Your Shopping Cart</DrawerHeader>

                    <DrawerBody>
                        {
                            checkout?.lineItems?.length ? checkout.lineItems.map(item => {
                                return <Grid key={item.id} templateColumns="repeat(1, 1fr)" gap={1}>
                                    <Flex alignItems="top" justifyContent="left">
                                        <CloseIcon cursor="pointer" onClick={() => removeLineItem(item?.id)} />
                                    </Flex>
                                    <Flex alignItems="center" justifyContent="center">
                                        <Image src={item.variant?.image?.src} />
                                    </Flex>
                                    <Flex alignItems="center" justifyContent="center">
                                        <Text>{item.title}</Text>
                                    </Flex>
                                    <Flex alignItems="center" justifyContent="center">
                                        <Text>{item.variant?.price?.amount}</Text>
                                    </Flex>
                                </Grid>
                            }) 
                            : 
                            <Box h="100%" w="100%">
                                <Text
                                    h="100%"
                                    display="flex"
                                    flexDir="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    Your cart is empty
                                </Text>
                            </Box>
                        }
                    </DrawerBody>

                    <DrawerFooter>
                        <Button
                            onClick={() => window.location = checkout.webUrl} // `http://shop.dandyhorsebikes.com/55664214170/checkouts/${checkout?.webUrl?.split('checkouts/')[1]}`}
                            colorScheme="blue"
                            disabled={!checkout?.lineItems?.length}
                            w="100%">Checkout
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}

export default Cart
