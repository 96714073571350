import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Hero from '../components/hero/Hero'
import { ShopContext } from '../context/ShopProvider'
import { Box, Image, Text, Flex } from '@chakra-ui/react'
import parse from 'html-react-parser'

const Blog = () => {
    const { fetchAllBlogPosts, blogPosts } = useContext(ShopContext)

    useEffect(() => {
        if (blogPosts.length === 0) {
            fetchAllBlogPosts();
        }
    }, [])

    return (
        <div>
            <Hero 
                isHomePage={false}
                content={{
                    imgSrc: "/images/mom-girl-md.jpg ",
                    headline: "E-bike Blog", 
                    subheadline: "Exploring the Power and Potential of E-Bikes!",
                    srcSet: "/images/mom-girl-md.jpg 1024w, /images/forest.jpg 3750w" 
                }}
            />
            <Flex  my='2rem' flexDirection={['column', 'column', 'row']} >
                {blogPosts?.map(post => 
                    <Box px={['1rem', '1rem', '3rem']}>
                        <Image src={post?.image?.src} />
                        <Text fontSize={['1.25rem', '1.25rem', '1.25rem',  '1.5rem']} mb='1.5rem'>{post.title}</Text>
                        <Text fontFamily='arial' fontWeight='normal' letterSpacing={.2} fontSize='.9rem'>{parse(post.summary_html)}</Text>
                        <Link to={`/blog/post/${post.handle}`}>
                            <Text mt='2rem' fontSize='1.25rem' fontWeight='bold' color='blue.600'>Read More -</Text>
                        </Link>
                    </Box>
                )}
            </Flex>


        </div>
    )
}

export default Blog