import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Image, Box } from '@chakra-ui/react'
import { ShopContext } from '../../context/ShopProvider'
import parse from 'html-react-parser'
import './blogpost.scss'

const BlogPost = () => {
    const { fetchOneBlogPost, blogPosts } = useContext(ShopContext)
    const { blogPostHandle } = useParams();
    const [blogPost, setBlogPost] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            let post;
            if (blogPosts.length > 0) {
                post = blogPosts.find(post => post.handle === blogPostHandle);
            } else {
                const response = await fetchOneBlogPost();
                post = response.data;
            }
            setBlogPost(post);
        }
        fetchData();
    }, [])

    return (
        <Box mx='auto' px='1rem' mb='3rem' className='blog-post' w={['100%', '60%']}>
            {console.log(blogPost)}
            <Image src={blogPost?.image?.src}/>
            {parse(blogPost?.body_html || '')}
        </Box>
    )
}

export default BlogPost