import { Box } from '@chakra-ui/react'
import Banner from '../components/banner/banner'
import Hero from '../components/hero/Hero'
import React from 'react'
import Services from '../components/Services'
import buyingGuideData from '../data/buyingGuideData'

const BuyingGuide = () => {
  return (
    <Box>
        <Banner />
        <Hero 
            isHomePage={false}
            content={{
                imgSrc: "/images/outdoor-feature.webp",
                headline: "Think Before You Buy, Plan Before You Ride",
                subheadline: "The Ultimate E-Bike Buying Guide",
                srcSet: "/images/mom-girl-md.jpg 1024w, /images/forest.jpg 3750w"
            }} />
        <Services
            servicesData={buyingGuideData?.servicesData || []} 
        />
    </Box>
  )
}

export default BuyingGuide