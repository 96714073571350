import React from 'react'
import { Box, Text, Image, useMediaQuery, Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import './hero.scss'

const Hero = ({isHomePage, content}) => {
    const [isMobile] = useMediaQuery("(max-width: 767px)")
    const headlinePositionSettingTop = isHomePage ? ['1.25rem', '2rem', '2rem', '2rem'] : 'unset';
    const headlinePositionSettingBottom = isHomePage ? 'unset' : ['0.5rem', '3.55rem', '5.5rem', '5.5rem'];

    return (
        <Box w='100%' position='relative'>
            <Image
                maxHeight='650px'
                width='100%'
                objectFit='cover'
                srcSet={content.srcSet}
                src={content.imgSrc}/>
            <Box
                w='100%'
                position='absolute'
                top={headlinePositionSettingTop}
                bottom={headlinePositionSettingBottom}>
                <Text    
                    textAlign={isHomePage ? 'left' : 'center'}
                    color='white'
                    fontWeight='normal'
                    fontSize={['1.25rem', '2rem', '3rem', '3rem']}
                    textShadow='1px 1px 2px black'>
                    {content.headline}      
                </Text>
                <Text    
                    textAlign={isHomePage ? 'left' : 'center'}
                    color='white'
                    fontWeight='normal'
                    fontSize={['1rem', '1.5rem', '1.75rem', '2.5rem']}
                    textShadow='1px 1px 2px black'>
                    {content.subheadline}      
                </Text>
            </Box>
            {isHomePage && 
                <Button
                    position='absolute'
                    bottom={['20%', '30%', '30%', '40%']}
                    left={['calc(50% - 75px)', 'calc(50% - 100px)', 'calc(50% - 150px)', 'calc(50% - 150px)']}
                    backgroundColor='blue.400'
                    color='white'
                    fontWeight='normal'
                    fontSize={['1rem', '1.25rem', '2rem', '2rem']}
                    w={['150px', '200px', '300px', '300px']}
                    h={['30px', '40px', '60px', '60px']}
                    boxShadow='2px 2px 8px #000'>
                    <Link className='shop-menu-link' to={`/collections/sales`}>Shop All Ebike Deals</Link>
                </Button>
            }
        </Box>
    )
}

export default Hero
