import React from 'react'
import { Link } from 'react-router-dom'
import { MdMenu } from 'react-icons/md'
import {
    Button, 
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Text,
    Grid,
    Icon,
    Image,
    useDisclosure
} from '@chakra-ui/react'
import ShopByType from './ShopByType'

function NavMenu() {

    const { isOpen, onClose, onOpen } = useDisclosure();
    const btnRef = React.useRef();

    return (
        <>
            <Button ref={btnRef} onClick={onOpen} cursor="pointer" w={30} h={30} variant='ghost'><Icon fill="white" cursor="pointer" as={MdMenu} w={30} h={30}>Menu</Icon></Button> 
            <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="sm" ref={btnRef}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader><Image src='/images/dark_logo.png' h='30px'></Image></DrawerHeader>
                    <DrawerBody>
                        <Grid onClick={onClose}>
                            <Text fontSize='24px' mb='1rem'><Link to="/collections/all">Shop All Ebikes</Link></Text>
                        </Grid>
                        <ShopByType styles={{display: 'block', textAlign:'left', fontSize:'24px', mb:'1rem'}} menuType='collections' onClose={onClose} />
                        <ShopByType styles={{display: 'block', textAlign:'left', fontSize:'24px', mb:'1rem'}} menuType='brands' onClose={onClose}/>
                        <Grid>
                            <Text onClick={onClose} fontSize='24px' mb='1rem'><Link to="/faq">Faq</Link></Text>
                            <Text onClick={onClose} fontSize='24px' mb='1rem'><Link to="/buying-guide">Buying Guide</Link></Text>
                            <Text onClick={onClose} fontSize='24px' mb='1rem'><Link to="/blog">Blog</Link></Text>
                            <Text onClick={onClose} fontSize='24px' mb='1rem'><Link to="/contact">Contact Us</Link></Text>
                        </Grid>
                    </DrawerBody>
                    <DrawerFooter textAlign="center">
                        <Text w="100%">Copyright 2021 Dandy Horse, LLC</Text>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default NavMenu
