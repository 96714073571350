const homePageData =  {
    featuredCollectionsData: [
        {
            imagePath: '/images/city_icon.png',
            title: 'Commuter e-Bikes',
            description: 'Light-weight and durable for getting around town',
            handle: 'commuter-ebikes'
        },
        {
            imagePath: '/images/comfort_icon.png',
            title: 'Cruiser e-Bikes',
            description: 'Built for those looking for a leisurely ride',
            handle: 'cruiser-ebikes'
        },
        {
            imagePath: '/images/sport_bike_icon.png',
            title: 'Mountain e-Bikes',
            description: 'Speedy and rugged for those riding wild',
            handle: 'mountain-ebikes'
        },
        {
            imagePath: '/images/helmet_icon.png',
            title: 'Bike Accessories',
            description: 'Safety, Security, Style and More',
            handle: 'accessories'
        }
    ],
    servicesData: [
        {
            imagePath: '/images/bike.png',
            title: 'E-Bike Sales',
            description: 'We offer a wide variety of E-bikes to satisfy all needs. And, we only source from the best brands so you can be confident making a selection.'
        },
        {
            imagePath: '/images/customer_service.png',
            title: 'Superb Customer Service',
            description: 'Our experts are always available to answer any and all questions you may have about E-bikes. We pride ourselves in providing 100% customer satisfaction.'
        },
        {
            imagePath: '/images/helmet_icon.png',
            title: 'Parts & Accessories',
            description: 'We stock dozens of accessories to keep your ride comfortable, safe, and running like new.'
        }
    ]
}

export default homePageData