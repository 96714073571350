import React, { useEffect, useContext, useState } from 'react'

// import function to register Swiper custom elements

// register Swiper custom elements
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Pagination, Navigation } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import { useParams, Link } from 'react-router-dom'
import { ShopContext } from '../../context/ShopProvider'
import { Box, Grid, Text, Button, Heading, Flex, Select, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Divider, Image, GridItem } from '@chakra-ui/react'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './productpage.scss';
import collectionMapper from './collectionMapper.js';
import ProductTabs from '../../components/ProductTabs';
// import { Rating } from '@mui/lab';
import _isEmpty from 'lodash/isEmpty';
import Highlights from '../../components/Highlights';
import reviews from './../../data/reviewData';

const ProductPage = () => {

    const { handle } = useParams()
    const [product, setProduct] = useState({});
    const [reviewsData, setReviewsData] = useState({});
    const [reviewsScore, setReviewsScore] = useState(0);
    const [selectedOptionValues, setSelectedOptionValues] = useState({})
    const [quantity, setQuantity] = useState(1)
    const [relatedAccessories, setRelatedAccessories] = useState([])

    const { addItemToCheckout, products, fetchProductReviews, setCurrentProduct, fetchProductWithMetafields } = useContext(ShopContext)

    const selectedVariant = product?.variants?.nodes?.filter(variant => Object.values(selectedOptionValues)?.every(optionValue => variant?.title?.includes(optionValue)))[0]
    
    useEffect(() => {
        const filteredProduct = products.filter(currentProduct => currentProduct.handle === handle)
        const gidSplit = filteredProduct[0] && filteredProduct[0]?.id?.split('/');
        const gidNum = gidSplit && gidSplit[gidSplit?.length -1];
        if (gidNum) {
            fetchProductWithMetafields(gidNum).then(response => {
                setCurrentProduct(response.data)
                setProduct(response.data)
            });
        }
        const filteredAccessories = products.filter(currentProduct => currentProduct.productType === 'Accessory' && currentProduct?.tags?.map(tag => tag.value).includes(filteredProduct[0]?.tags[0]?.value))
        setRelatedAccessories(filteredAccessories)
    }, [products, handle])

    useEffect(() => {
        if (!_isEmpty(product)) {
            const productReviews = reviews?.find(currentReviews => currentReviews.productId === product?.id);
            setReviewsData(productReviews);
            setReviewsScore(productReviews?.reviews?.map(currentReview => currentReview.score).reduce((a, b) => a + b) / productReviews?.reviews?.length || 0)
            // setReviewsData(reviews.filter(currentReviews => currentReviews.productId === parsedProductId).reviews)
            // fetchProductReviews(parsedProductId).then((reviews) => {
            //     console.log(reviews)
            //     setReviewsData(reviews.data)
            // }).catch((e) => {
            //     console.log('Product reviews not found')
            // })
        }
    }, [product])

    if (!product?.title) return <Box display='flex' justifyContent='center' mt='5rem' mb='5rem'><Image w='200px' h='200px' src='/images/loading.gif' /></Box>

    const numberOfReviews = reviewsData?.reviews?.length
    const reviewsCountText = `${numberOfReviews ? numberOfReviews : 0} ${numberOfReviews === 1 ? ' Review' : ' Reviews'}`
    // const reviewsScore = reviewsData.reviews.reduce((a, b) => a.score + b.score) / reviewsData.reviews.length || 0
    const isProductPreOrder = product?.metafields?.nodes?.find((metafield) => metafield.key === 'pre_order_status')?.value === 'true';
    const productPreOrderEta = product?.metafields?.nodes?.find(metafield => metafield.key === 'pre_order_eta')?.value
    const parseMetafieldValue = (metafields, metafield) => metafield !== 'frame' ? metafields.find(currentMetafield => currentMetafield.key === metafield)?.value : metafields.find(currentMetafield => currentMetafield.key === metafield)?.value?.includes('Foldable'); 

    return (
        <Box mt='2rem'>
            <Box w={[ "100%", "90%"]} m="auto">
                <Swiper 
                    effect={'flip'}
                    grabCursor={true}
                    pagination={true}
                    navigation={true}
                    modules={[EffectFlip, Pagination, Navigation]}
                    loop={true}
                    className="productPageSwiper">
                    {product.images.nodes.map((image, index) => <SwiperSlide key={`${image.url}-${index}`}><Image src={image.url}/></SwiperSlide>)}
                </Swiper>
                <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(1, 1fr)"]} m="auto" px={["0.5rem", "1rem", "0"]}>
                    {/* <Flex width='100%' justifyContent="center" alignItems="top"> */}
                        {/* <Carousel
                            infiniteLoop={true}
                            dynamicHeight={true}
                            showThumbs={false}>
                            {product.images.nodes.map((image, index) => <InnerImageZoom key={`${image.url}-${index}`} src={image.url} zoomSrc={image.url} hasSpacer={true} fullscreenOnMobile={true} />)}
                        </Carousel>         */}
                    {/* </Flex> */}
                    <Flex flexDirection="column" alignItems="left" px={["0.5rem", "0.5rem", "2rem"]} mt="1rem" ml={[0, 0, 0, '1rem', '2rem']}>
                        <Heading>{product.title}</Heading>
                        {/* <Text mb='0.5rem'>{collectionMapper[product.collections[0].handle]}</Text> */}
                        {/* <Flex pb='0.5rem'><Rating value={reviewsScore} readOnly precision={0.5}/><Text ml='0.5rem'>{reviewsCountText}</Text></Flex> */}
                        <Divider mb='1rem' borderColor='gray.500'/>
                        <Box>
                            <Text fontWeight="bold" fontSize="20px" pb="0.5rem">{`$${selectedVariant?.price}`}</Text>
                            {selectedVariant?.compareAtPrice > 0 && (
                                <Flex>
                                    <Text textDecoration='line-through' color='red.400'>{`$${selectedVariant?.compareAtPrice}`}</Text>
                                    <Text pl='0.5rem' color='red.400'>{`Save ${((1 - (selectedVariant?.price / selectedVariant?.compareAtPrice)) * 100).toFixed(2)}%`}</Text>
                                </Flex>
                            )}
                        </Box>
                        
                        {/* <Text pb='1rem'>Starting at $100/mo with Random Financier</Text> */}
                        <Divider mb='1rem' borderColor='gray.500'/>
                        {product.productType === 'E-Bike'
                            ? <>
                                <Highlights
                                    motorWatts={parseMetafieldValue(product.metafields.nodes, 'motor_wattage')}
                                    topSpeed={parseMetafieldValue(product.metafields.nodes, 'top_speed')}
                                    maxRange={parseMetafieldValue(product.metafields.nodes, 'max_range')}
                                    foldable={parseMetafieldValue(product.metafields.nodes, 'frame')}
                                    propulsionType={parseMetafieldValue(product.metafields.nodes, 'propulsion_type')}/>
                                <Divider mb='1rem' borderColor='gray.500'/>
                                </>
                            : null }
                        {product.options.map(option => {
                            if (option.name != 'Title') {
                                if (option.name === 'Color') {
                                    return (
                                        <>
                                            <Text mb='0.5rem'>{option.name}</Text>
                                            <Grid mb='1rem' templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']} border='1px solid' borderColor='gray.200' borderRadius='8px' alignItems='center' p='1rem'>
                                                <GridItem>
                                                    <Select mb='1rem' onChange={(e) => setSelectedOptionValues({...selectedOptionValues, [option.name]: e.target.value})}>
                                                        {option.values.map(value => <option value={value}>{value}</option>)}
                                                    </Select>
                                                </GridItem>
                                                <GridItem>
                                                    <Image mx='auto' maxWidth='200px' src={selectedVariant?.image?.url} />
                                                </GridItem>
                                            </Grid>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <Text mb='0.5rem'>{option.name}</Text>
                                            <Select mb='1rem' onChange={(e) => setSelectedOptionValues({...selectedOptionValues, [option.name]: e.target.value})}>
                                                {option.values.map(value => <option value={value}>{value}</option>)}
                                            </Select>
                                        </>
                                    )
                                }
                            }
                        })}
                        <Text mb='0.5rem'>Quantity</Text>
                        <NumberInput defaultValue={1} min={1} max={5} mb='1rem' onChange={(value) => setQuantity(value)}>
                            <NumberInputField onChange={(e) => setQuantity(e.target.value)}/>
                            <NumberInputStepper >
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <Button isDisabled={!isProductPreOrder && selectedVariant?.inventoryQuantity === 0} _hover={{ bg: "blue.700" }} mb='1rem' fontSize="1.5rem" fontWeight='normal' backgroundColor="blue.500" color="white" onClick={() => addItemToCheckout(selectedVariant.id, quantity)}>{isProductPreOrder ? selectedVariant?.inventoryQuantity === 0 ? 'Pre Order' : 'Add to Cart' : selectedVariant?.inventoryQuantity === 0 ? 'Sold Out' : 'Add to Cart'}</Button>
                        {productPreOrderEta && selectedVariant?.inventoryQuantity === 0 && <Text color='red.400'>{`Pre-Order ETA ${productPreOrderEta}`}</Text>}
                        <Divider mb='2rem' borderColor='gray.500'/>
                        <Text pb="2rem" color="gray.600" lineHeight='1.6' fontSize='1.2rem'>{product.description}</Text>
                    </Flex>
                </Grid>
             </Box>
             {product.productType === 'E-Bike' ? relatedAccessories?.length > 0 && <Box  mx="auto" maxWidth='1200px' mt={['1rem', '4rem']} mb='8rem'>
                <Box mx='1rem' border='1px solid' borderColor='gray.200' borderRadius='4px' pt='2rem' mb='6rem' boxShadow='2px 2px 6px #E2E8F0'>
                    <Heading textAlign='center' mb='2rem'>Accessorize Your Ride</Heading>
                    <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)", `repeat(${relatedAccessories?.length <= 5 ? relatedAccessories.length : '5'}, 1fr)`]}>
                    {relatedAccessories?.map(accessory => (
                        <GridItem display='flex' flexDirection='column' alignItems='center' mb='1rem'>
                            <Link className='accessory-description-link' to={`/products/${accessory.handle}`} key={accessory.id}>
                                <Text textAlign='center'>{accessory.title}</Text>
                                {/* <Text fontFamily='montserrat'>{`$${accessory.variants[0].price}`}</Text>{} */}
                                <Image src={accessory.images[0].src} maxWidth='100px' height='90px' mb='1rem'></Image>
                            </Link>
                            <Button width='90%' maxWidth='200px' _hover={{ bg: "blue.700" }} mb='1rem' mx='auto' fontSize="1rem" fontWeight='normal' backgroundColor="blue.500" color="white" onClick={() => addItemToCheckout(accessory.variants[0].id, quantity)}>Add to Cart</Button>
                        </GridItem>
                    ))}
                    </Grid>
                </Box>
            </Box> : null}
            <Box maxWidth='1200px' mx="auto">
                {product.productType === 'E-Bike' && <ProductTabs vendor={product.vendor} metafields={product.metafields.nodes} reviewsData={reviewsData} reviewsCountText={reviewsCountText} reviewsScore={reviewsScore}/>}
            </Box>
         </Box>
    )
}

export default ProductPage
