import engineIcon from './icons/engine.svg'
import batteryIcon from './icons/battery.svg'
import weighingMachine from './icons/weighing-machine.svg'
import display from './icons/display.svg'
import fork from './icons/fork.svg'
import transmission from './icons/transmission.svg'
import wheel from './icons/wheel.svg'
import stem from './icons/stem.svg'
import speedometer from './icons/speedometer.svg'
import crankset from './icons/crankset.svg'
import chain from './icons/chain.svg'
import diskBrake from './icons/disc-brake.svg'
import saddle from './icons/saddle.svg'
import frame from './icons/frame.svg'
import derailleur from './icons/derailleur.svg'
import handlebar from './icons/handlebar.svg'
import distance from './icons/distance.svg'
import propulsion from './icons/propulsion.svg'
import controller from './icons/controller.svg'
import headlight from './icons/headlight.svg'
import guarantee from './icons/guarantee.svg'

const iconMapper = {
    motor_type: engineIcon,
    battery_wattage: batteryIcon,
    weight: weighingMachine,
    display: display,
    fork: fork,
    shifter_rear: transmission,
    tires: wheel,
    stem: stem,
    motor_type: engineIcon,
    throttle_pas: speedometer,
    motor_wattage: engineIcon,
    crankset: crankset,
    chain: chain,
    brakes: diskBrake,
    seatpost: saddle,
    saddle: saddle,
    brakes_type: diskBrake,
    wheels: wheel,
    frame: frame,
    derailleur: derailleur,
    rims: wheel,
    handlebar: handlebar,
    max_carry_weight: weighingMachine,
    top_speed: speedometer,
    battery_spec: batteryIcon,
    battery_volts: batteryIcon,
    max_range: distance,
    range_spec: distance,
    propulsion_type: propulsion,
    controller: controller,
    light: headlight,
    warranty: guarantee
}

export default iconMapper;