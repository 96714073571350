import React, { useContext} from 'react'
import { Menu, MenuButton, MenuList, Grid, GridItem, MenuItem} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { ShopContext } from '../context/ShopProvider'
 
const ShopByType = ({styles, menuType, onClose}) => {
    const { collections, brands } = useContext(ShopContext)
    const isCollections = menuType === 'collections';
    const items = isCollections ? collections : brands;

    return (
        <Menu placement='right-end' >
            <MenuButton {...styles}>{`Shop by ${isCollections ? 'Type': 'Brand'}`}</MenuButton>
            <MenuList zIndex={1000} textAlign='left' minWidth='100px'>
                <Grid>
                    {items.map((item, index) => 
                        <GridItem key={item + index} color='gray.800'>
                            <MenuItem onClick={onClose}>                   
                                <Link to={`/${isCollections ? 'collections' : 'brand'}/${isCollections ? item.handle: item}`}>{isCollections ? item.title: item}</Link>
                            </MenuItem>             
                        </GridItem>)
                    }
                </Grid>  
            </MenuList>
        </Menu>
    )
}

export default ShopByType
