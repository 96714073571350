import React from 'react'
import { Flex, Heading, Text, Icon, Divider, Box } from '@chakra-ui/react'
import { FiPhoneCall } from 'react-icons/fi'
import { GiSmartphone } from 'react-icons/gi'
import { BsChatDots } from 'react-icons/bs'
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im'

const Assistance = () => {
    return (
        <Flex my='4rem' py='3rem' px='1rem' alignItems='center' backgroundColor='blue.600' direction='column' color='white'>
            <Box maxWidth='600px'>
                <Heading fontSize={['32px', '32px', '64px']} mb='2rem'>Electric Bikes From Dandy Horse</Heading>
                <Text fontFamily='montserrat' fontSize={['16px', '20px']} mb='2rem'>Dandy Horse stocks only the best e-Bikes on the market. Guaranteed to have the best prices at the highest quality, we strive to provide you with exceptional customer service.</Text>
                <Divider mb='2rem' borderWidth='1px' />
                <Heading>Check out what our customers have to say about Dandy Horse</Heading>
                <Box position='relative'>
                    <Icon as={ImQuotesLeft} w={'100px'} h={'100px'} opacity='0.25' position='absolute'></Icon>
                    <Text fontFamily='montserrat' p='1.5rem' pb='0.5rem' pt='3rem' fontStyle='italic'>I was a little nervous about spending so much money on a bike. In the end, it was totally worth it. I find myself using my new bike more than I ever imagined. It rides like they said it would, comfortable, good range, and it is fast! The guys at Dandy Horse made it easy for me to take the leap. Thanks!</Text>
                    <Text as='cite' pl='1.5rem' fontSize='14px'>Steve M. Mar 31, 2021</Text>
                </Box>
                <Box position='relative' mb='2rem'>
                    <Text fontFamily='montserrat' p='1.5rem' pb='0.5rem' pt='3rem' fontStyle='italic'>Buying from Dandy Horse was easy and straightfoward. I had plenty of questions about the bikes and their staff was very informative. The bike arrived quickly and without any issues.</Text>
                    <Text as='cite' pl='1.5rem' fontSize='14px'>Mark J. Apr 5, 2021</Text>
                    <Icon as={ImQuotesRight} w={'100px'} h={'100px'} opacity='0.25' position='absolute' right='0' bottom='10px'></Icon>
                </Box>
                <Divider mb='2rem' borderWidth='1px' />
                <Text fontFamily='montserrat' fontSize={['20px', '26px', '32px']}  mb='2rem'>Can't find what you need, or have questions about what's available? Contact us!</Text>
                <Text fontFamily='montserrat' fontSize={['20px', '26px', '32px']}  mb='2rem'>Our experts are always available and eager to help you find the e-Bike that's best for you.</Text>
                <Divider mb='2rem' borderWidth='1px' />
                <Text mb='0.5rem' fontSize={['20px', '26px', '28px']} ><Icon outline="white" cursor="pointer" as={FiPhoneCall} w={[5, 30]} h={[5, 30]} mr='1rem'/> Call us: 915-224-2899</Text>
                {/* <Text mb='0.5rem' fontSize={['20px', '26px', '28px']} ><Icon outline="white" cursor="pointer" as={GiSmartphone} w={[5, 30]} h={[5, 30]} mr='1rem'/> Text us: 1-888-888-8888</Text> */}
                <Text fontSize={['20px', '26px', '28px']} ><Icon outline="white" cursor="pointer" as={BsChatDots} w={[5, 30]} h={[5, 30]} mr='1rem'/> Message us: Click Here</Text>
            </Box>
        </Flex>
    )
}

export default Assistance
