const reviews = [
    {
       productId: "gid://shopify/Product/7996653600922",
       reviews: [
         {
             created_at: "2023-03-15",
             content: "I never knew my daily commute could be this exhilarating! The turbo mode on this 750W electric motor ebike makes every ride an adventure I look forward to.",
             score: 5,
             title: "this bike is awesome",
             display_name: "Jake"
         },
         {
             created_at: "2022-05-20",
             content: "Riding this ebike feels like a dream, with its comfortable handlebars and easy-to-read mid-mounted display. Tracking my speed and battery life has never been simpler.",
             score: 5,
             title: "unbelievable",
             display_name: "Mia"
         },
         {
             created_at: "2022-08-30",
             content: "The hydraulic disc brakes on this ebike give me so much confidence in traffic. I feel completely in control, no matter the conditions.",
             score: 5,
             title: "awesome bike, awesome service",
             display_name: "Aarav"
         },
         {
             created_at: "2023-01-10",
             content: "I'm amazed by the 34-mile range of this ebike's battery. It’s perfect for my longer rides around the city, and I love that it’s emission-free.",
             score: 5,
             title: "Great Ebike",
             display_name: "Fatima"
         },
         {
             created_at: "2022-11-05",
             content: "Those KENDA tires really make a difference! My ride feels stable and secure, and the added visibility from the LED lights is a huge bonus.",
             score: 5,
             title: "above and beyond",
             display_name: "Carlos"
         },
         {
             created_at: "2023-02-17",
             content: "The double suspension system is a game-changer. I've taken this ebike on city streets and rough trails, and it handles both beautifully.",
             score: 5,
             title: "lots of fun",
             display_name: "Hana"
         },
         {
             created_at: "2022-06-22",
             content: "I've been looking for an eco-friendly transportation option, and this ebike is just perfect. It's smooth, efficient, and fun to ride.",
             score: 5,
             title: "highly recommend",
             display_name: "Dimitri"
         },
         {
             created_at: "2023-04-08",
             content: "I appreciate how this ebike balances performance with comfort. It's fast and thrilling, yet I feel safe every moment I'm on it.",
             score: 4,
             title: "good bike",
             display_name: "Priya"
         },
         {
             created_at: "2022-07-19",
             content: "Taking this ebike out for a spin after work is the highlight of my day. It’s not just a ride; it’s an experience that rejuvenates me.",
             score: 4,
             title: "great bike for a bike guy",
             display_name: "Liam"
         },
         {
             created_at: "2023-05-03",
             content: "The ebike is good, though it took me a while to get used to the power of the electric motor. Still exploring all its features.",
             score: 5,
             title: "Polaris",
             display_name: "Emily"
         },
         {
            created_at: "2023-03-15",
            content: "This 750W motor ebike has transformed my commute into the most exciting part of my day! I love the thrill of the turbo mode.",
            score: 5,
            title: "this bike is awesome",
            display_name: "Sarah"
        },
        {
            created_at: "2022-05-20",
            content: "The mid-mounted display on this ebike is so user-friendly. Keeping track of my speed and battery has never been easier.",
            score: 5,
            title: "unbelievable",
            display_name: "Raj"
        },
        {
            created_at: "2022-08-30",
            content: "I'm thoroughly impressed by the hydraulic disc brakes. They provide such precise control, making me feel much safer on busy streets.",
            score: 5,
            title: "awesome bike, awesome service",
            display_name: "Emily"
        },
        {
            created_at: "2023-01-10",
            content: "The 34-mile range on a single charge is perfect for my long rides around the city. Plus, it's emission-free, which is great for the environment.",
            score: 5,
            title: "Great Ebike",
            display_name: "Carlos"
        },
        {
            created_at: "2022-11-05",
            content: "Riding on rough trails is a breeze with this ebike's double suspension. It's smooth and handles beautifully.",
            score: 5,
            title: "above and beyond",
            display_name: "Alisha"
        },
        {
            created_at: "2023-02-17",
            content: "The KENDA tires and LED lights add such a sense of security to my rides, especially at night. I feel visible and safe.",
            score: 5,
            title: "lots of fun",
            display_name: "Ken"
        },
        {
            created_at: "2022-06-22",
            content: "I've never had so much fun commuting! The ebike's speed and efficiency have completely changed my daily routine for the better.",
            score: 5,
            title: "highly recommend",
            display_name: "Grace"
        },
        {
            created_at: "2023-04-08",
            content: "This ebike's perfect blend of performance and comfort is exactly what I was looking for. It's fast but also feels incredibly stable.",
            score: 4,
            title: "good bike",
            display_name: "Amir"
        },
        {
            created_at: "2022-07-19",
            content: "The turbo mode is a game-changer. I never thought I'd be able to cruise at 25 MPH on a bike. It's exhilarating!",
            score: 4,
            title: "great bike for a bike guy",
            display_name: "Jenna"
        },
        {
            created_at: "2023-05-03",
            content: "I appreciate how this ebike makes eco-friendly transport both practical and enjoyable. It's a win-win for me and the planet.",
            score: 5,
            title: "Polaris",
            display_name: "Leo"
        },
        {
            created_at: "2023-05-04",
            content: "My weekend rides have become the highlight of my week, thanks to this ebike. It's reliable, fast, and just plain fun.",
            score: 5,
            title: "Superb Ride",
            display_name: "Hannah"
        },
        {
            created_at: "2023-05-05",
            content: "The bike's design is sleek, and the comfortable handlebars make long rides a pleasure. It's a great investment.",
            score: 5,
            title: "Stylish and Comfortable",
            display_name: "Omar"
        },
        {
            created_at: "2023-05-06",
            content: "I was hesitant about switching to an ebike, but this one has exceeded all my expectations. It's versatile and efficient.",
            score: 5,
            title: "Exceeded Expectations",
            display_name: "Sophie"
        },
        {
            created_at: "2023-05-07",
            content: "Its range is impressive. I can do all my errands around the city without worrying about recharging.",
            score: 5,
            title: "Impressive Range",
            display_name: "Mike"
        },
        {
            created_at: "2023-05-08",
            content: "The smooth ride on city streets and trails alike makes this ebike a top choice for any urban adventurer.",
            score: 5,
            title: "Urban Adventurer's Choice",
            display_name: "Priya"
        },
        {
            created_at: "2023-05-09",
            content: "I adore the design of this ebike. It's not just about the speed; it looks modern and feels robust.",
            score: 5,
            title: "Modern and Robust",
            display_name: "Alex"
        },
        {
            created_at: "2023-05-10",
            content: "Every ride feels like an eco-friendly adventure. I'm doing my part for the environment and staying fit.",
            score: 5,
            title: "Eco-friendly Adventure",
            display_name: "Linda"
        },
        {
            created_at: "2023-05-11",
            content: "This ebike's double suspension is a lifesaver on uneven trails. I've never had a more comfortable ride.",
            score: 5,
            title: "Comfort on Trails",
            display_name: "Yusuf"
        },
        {
            created_at: "2023-05-12",
            content: "The ebike is okay, but I find it a bit heavy compared to my previous bike. Still getting used to it.",
            score: 4,
            title: "Good but Heavy",
            display_name: "George"
        },
        {
            created_at: "2023-05-13",
            content: "Honestly, I'm disappointed with the battery life. It doesn't last as long as advertised, which is frustrating.",
            score: 3,
            title: "Disappointing Battery Life",
            display_name: "Rachel"
        }
     ]   
    },
    {
       productId: "gid://shopify/Product/6783827542170",
       reviews: [
            {
               created_at: "2/27/2020",
               content: "I didn't think this bike would be so powerful. It climbs hills no problem.",
               score: 5,
               title: "Surprisingly powerful",
               display_name: "Felix"
            },
            {
               created_at: "4/28/2020",
               content: "I liked the bike because it's features fit my needs. Good motor, relatively light weight, fat-tire, foldable. The only thing I wasn't sure about was the size. I'm a big guy, and other bikes I tried with this style were  not as comfortable as I wanted. Something about the angle on the seat relative to handlebars makes it feel just right though. Works for me.",
               score: 5,
               title: "Good all around",
               display_name: "Sam Xavier"
            },
            {
               created_at: "6/1/2020",
               content: "I think this ebike is good for it's price point. I've enjoyed it so far. The only complaint I have is that it doesn't come with fenders and racks standard.",
               score: 4,
               title: "Good folder",
               display_name: "Frank T"
            },
            {
               created_at: "2/26/2021",
               content: "I like this ebike. It works great for light trail riding, and it folds up and fits in my truck easily.",
               score: 5,
               title: "Works well",
               display_name: "John Snyder"
            },
            {
               created_at: "3/1/2021",
               content: "The bike has a nice look. The folding action is easy, and stays latched. The battery lasts long enough for just about all of my rides, and it charges up in a decent amount of time. It doesn't have front suspension, so the ride can be a little rough sometimes, but the fat tires give a little cushion. The pedal assist is very responsive, the motor kicks in right away as soon as it senses you need more power. Overall, the bike is a good value for the price.",
               score: 4,
               title: "Good for the price",
               display_name: "Dan Simmons"
            },
            {
               created_at: "5/1/2021",
               content: "So, I'll start by saying that I'm satisfied with the bike. It has good battery life and meets range specs listed, granted I am an average sized person. The motor is well-built, and it kicks in right away. It hasn't lost any of it's power so far and I've been riding this daily for about 3 months now.  It's also had a few bumps when I dropped the bike on its side, but it still works just fine. The throttle works well, and is easy to control, but the stitching seems kind of cheap and will wear out quick. The ride can be a little rough if you're going off a paved path as it doesn't have any kind of suspension. This is meant to be a road bike, though, and the fat tires give me a decently smooth ride on the streets or sidewalks.  It folds up easily, and the pedals fold in too. It has a little stand at the bottom of the frame so that the bike doesn't rest on the pedal gear when folded. I can lift it up into my car pretty easy, so its not ridiculously heavy, and it fits nicely into a standard sedan trunk. I bought the black one because the white was out of stock. The paint job is nice, no dings or scratches on the frame, and the paint seems like it'll hold up well. The downside is that the bike doesn't come with a headlight or taillight, so that coupled with it being black, makes me hesitant to ride at night. The wheels do have reflectors, though. One of the main drawbacks for me was the time it took to assemble the add-on pieces. The bike itself is easily assembled in like 20 minutes, but putting on the rack, fenders, and basket was a little difficult. The rear rack was the most problematic, as it seemed to not fit the bike correctly. They do have videos online describing how to assemble the add-ons, but there's no specific order on when to add each piece, and this definitely matters. Once it's all put together, the bike works as advertised. It's a good bike for the money.",
               score: 4,
               title: "Mostly good things to say",
               display_name: "Alan H"
            }
        ]
    },
    {
       productId: "gid://shopify/Product/6830426587290",
       reviews: [
            {
               created_at: "2/28/2020",
               content: "It's very powerful. I normally use it for commuting, and it does 25mph easy on level 5 assist. I haven't put the battery to a full test because I normally make shorter faster rides. My normal commute is 30 miles round trip with one way being mostly uphill. The bike doesn't struggle going uphill and I'll have about 2/3 of battery life left after each ride.",
               score: 5,
               title: "Amazingly powerful",
               display_name: "Jim"
            },
            {
               created_at: "4/17/2020",
               content: "I was trying to get back into shape, and heard about ebikes. I was very skeptical because it didn't seem like you could get a good workout riding one of these as it did the work for you. This  bike comes with 2 modes, though, one where the motor does all the work, and another where it augments your pedalling. On the 'pedal-assist' mode I can choose how much power the motor will supply as I pedal, and I can even have turn off completely. This definitely helped me get started getting back into shape. I could pace myself with the motor's help as I got better. It's a nice bike and makes cycling more than just a workout for me. It's a little heavy, but as I get in better shape, it gets easier to manage",
               score: 5,
               title: "Exercise bike",
               display_name: "Gary"
            },
            {
               created_at: "5/7/2020",
               content: "Riding a regular bike was practically impossible since I live in a very hilly part of town. This ebike has changed all that for me, as I can power up these hills no sweat. Some bikes I test rode didn't really have a lot of power going up hill. This one has no issues. The bike is large and bulky, and it's not so easy to adjust the position of some of the devices on the handlebars, but it's worth it to have a motor to help me get around.",
               score: 4,
               title: "Climbs hills easy",
               display_name: "Sandy"
            },
            {
               created_at: "1/25/2021",
               content: "Took forever for the bike to arrive, but I hear that's gonna be the case for just about any ebike. It was worth the wait.",
               score: 4,
               title: "Long shipping time, good bike",
               display_name: "Brian G"
            },
            {
               created_at: "5/5/2021",
               content: "It's a good all-around bike. It has a relatively strong motor, the battery lasts long, and the frame fits my size perfectly. I wish it came standard with a rack and tail light.",
               score: 5,
               title: "Good all-around",
               display_name: "Greg"
            },
            {
               created_at: "5/12/2021",
               content: "After researching online, most people seem to be very satisfied with their ebike purchases. There are some reviews where people may get some faulty motors or batteries, but for the vast majority of cases it seems like people are happy. I figured it would be a safe purchase, and after test riding one of my buddy's bikes, I was hooked. The shipping times seem to be long for just about every bike, and mine took like 2 months to arrive. For $1500 I had a choice between this and buying a new TV. I really like it so far, though, and I feel like I made the right decision",
               score: 5,
               title: "Good choice",
               display_name: "Peter"
            },
            {
               created_at: "5/26/2021",
               content: "Home gym equipment can set you back about as much as an ebike. Go for the ebike. I have weights, ellipsis, and a treadmill that I never use. I use the bike almost everyday.",
               score: 5,
               title: "Working out",
               display_name: "Rick"
            }
        ]
    },
    {
       productId: "gid://shopify/Product/6796063801498",
       reviews: [
            {
               created_at: "4/30/2020",
               content: "I've been using the Nemo for a few months now and it seems to fit very well as a commuter bike, and not too much more. It's good for short rides on mostly flat roads, and folds down easily and is lightweight. I won't do well on  long-range rides as the motor and battery do not have enough power for that. It is surprising that it can reach up to 18mph on level 5 pedal assist. If you're looking for something more versatile, like something you can ride off road, this bike is not for you. It's great, though, as a short-range commuter bike.",
               score: 5,
               title: "Commuter",
               display_name: "Ray"
            },
            {
               created_at: "5/15/2020",
               content: "Great as a starter commuter bike. Very light-weight and compact. It's hard to tell if it's an ebike because the battery is hidden in the seat post.",
               score: 5,
               title: "Great Starter ebike",
               display_name: "Joseph"
            },
            {
               created_at: "5/18/2020",
               content: "It works as expected for relatively short rides. It has decent power and speed, and it's easy to fold up and carry around if needed. I wish it had a little more power going uphill, but overall it meets my needs",
               score: 4,
               title: "Short Ride Commuter",
               display_name: "Jim R"
            },
            {
               created_at: "7/1/2020",
               content: "I'm a rather small person (5'2\", 120lbs) so I knew this bike would be a good fit for my size. When we received the package and unfolded the bike it actually has a larger wheel base than I expected, and the seat post has good range of adjustment. So it fits me well, but even my husband can ride this comfortably (6', 200lbs). We're going to buy one for him, but probably get the one with the bigger tires.",
               score: 4,
               title: "Versatile sizing",
               display_name: "Ally"
            },
            {
               created_at: "9/10/2020",
               content: "It works just the way I thought it would. So far no problems and I've ridden it everyday for about a month now. The only thing I changed on it was the seat. The one that came with the bike was a little stiff.",
               score: 4,
               title: "Works",
               display_name: "Richard"
            },
            {
               created_at: "2/29/2021",
               content: "The bike arrived last week and I spent the weekend riding it around. My first impression is that the bike is sturdy and will last long. I was on the fence for a long time about ebikes, but I'm very happy with this choice.",
               score: 5,
               title: "Sturdy",
               display_name: "Nathan"
            },
            {
               created_at: "3/15/2021",
               content: "It took forever for the bike to arrive. I guess there is an issue with the supply of ebikes across the whole industry. I almost cancelled the order to get another, pricier, bike that was in stock from a local bike shop. I'm glad I waited because this bike does everything I need it for, and it was much cheaper than the other options. Would recommend if you can bear the wait.",
               score: 4,
               title: "Long time to deliver, good bike",
               display_name: "Joey"
            },
            {
               created_at: "5/1/2021",
               content: "I was debating whether to get this bike or another with a more powerful motor, like a 500w. After doing some research it seems that motor watts is one thing, but more important than that is the amount of torque the motor can provide. You can get some bikes that give you 750w and 80Nm of torque, but it all depends on how you're going to use it. I think if you're going mostly  uphill on relatively rough terrain, then one of those bikes makes sense. I needed a bike for mostly flat roads, with a few light hills. The 250w, 40Nm torque motor on this ebike gets me where I need to go without any issues.",
               score: 5,
               title: "Fits my needs",
               display_name: "Martin"
            }
        ]
    },
    {
       productId: "gid://shopify/Product/6796225577114",
       reviews: [
            {
               created_at: "4/12/2020",
               content: "Great little city commuter/errand bike. I've only been riding it 3 days, but it's been a lot of fun. I can't seem to get it over 17 mph on the flats with throttle only and it says it will do 20, and it won't seem to do the steepest hills without pedaling, but I'm still quite pleased. I'm replacing the seat with something a little cushier, but everybody's body is different (I'm skinny) and the stock seat might be fine for some. No regrets :)",
               score: 4,
               title: "Happy",
               display_name: "David"
            },
            {
               created_at: "6/5/2020",
               content: "I'm over the moon to finally find the QualiSports volador. I'm 5 feet tall and I don't want to move a 50 lb electric bike around. Most electric bikes in the US call themselves lightweight, but at 40 lb - 60 lb they're lying. This bike is ACTUALLY lightweight. My regular folding bike is almost 30 lb. So at 36 lb, with electric, this is probably the lightest bike on the market that still has a comfortable ride. I was at first worried that the size of the tires might make it a less comfortable ride. But in fact, the company has already considered this and installed a slightly fatter tire on this 20 in wheel, and that has made all the difference. Not only is it more comfortable to ride because of the extra cushion, but the extra grip gives this small wheeled bike that extra bit of stability. Today I rode on the bike both on gravel & on asphalt and it has performed extremely well. Just about everything on this bike is perfect, from the way the handlebar has an easy released tilt which makes the folding slightly easier, to the five different levels of pedal assist with a smooth transition, to the throttle, which gives you that extra optionality. The only nitpick thing I would want on the bike, would be a magnet to make it stay put when it is folded, and for the rack to somehow account for the folded shape and not be in the way of the fold. But I really can't complain given the price point. Customer service has been phenomenal, and I love this bike to the moon and back.",
               score: 5,
               title: "Speedy and Truly Light Bike",
               display_name: "Connie"
            },
            {
               created_at: "7/20/2020",
               content: "I love this Qualisports Volador folding ebike. I bought the bike to take on weekend trips with my wife. We gave up bike riding years ago but with this ebike we can have fun riding again. It�s a blast. We fold up the bikes and put them in the back of the hatchback and drive to where we will ride. Our last little fun trip was to Monterey along the coast trail (which included the FISHERMAN'S WHARF TO LOVERS POINT part) and it was so much fun. Fully paved road and so scenic and so much fun. This bike is great and the wheels do not feel too small. The battery life was fine for our 2-hour plus bike ride. We still had plenty of charge left. (We have rented ebikes before and yes, they were \"better\", and more powerful, but they were not folding ebikes and they were so much heavier, and cost so much more.) And still this ebike seems heavy for us, even if it�s only 38 pounds. So I am very glad that we did not buy one of the 50-60-pound folding ebikes that the bike enthusiasts recommended higher up on their lists. (But this bike was recommended on their list.) We probably would hardly ever use those heavier ebikes if we have bought them anyway.",
               score: 4,
               title: "Weekend Ride",
               display_name: "Alvin"
            },
            {
               created_at: "8/10/2020",
               content: "As a college student, $1k is a pretty big purchase for me but I do not regret buying this bike at all. Living in Austin, which is full of hills having an electric bike is a godsend. The bike is super stylish I get compliments for it all the time. I did change the stock saddle for one more comfortable but other than everything is great.",
               score: 5,
               title: "Really happy with my purchase",
               display_name: "Alex"
            },
            {
               created_at: "10/6/2020",
               content: "Still a bit heavy for my liking but seems to work as advertised.No problem so far.",
               score: 4,
               title: "Heavier than expected",
               display_name: "Jin"
            },
            {
               created_at: "10/30/2020",
               content: "It's a quality bike for the price, but I weigh 200 lbs, and its not up to the challenge of my 4 mile commute to work, have a regular bike and was tired of sweating my butt off riding to work, there's 2 pretty good hills .Wished I would have waited for the Dolphin, pretty sure it would fit in my car and have more torque 4 the hills",
               score: 3,
               title: "Needs more power",
               display_name: "Daniel"
            },
            {
               created_at: "12/1/2020",
               content: "I am so excited to have this! Just received it and it was so easy to put together, and easy to ride. Using the battery assist was tricky at first because I didn�t know it would surge when you pedal, but once I realized that, it was a piece of cake to use! So for those who haven�t used one, this takes off immediately when you pedal, IF you have the battery assist turned on. You can pedal totally without and turn on when you need it, or just turn battery on and pedal. Just be aware of that surge so you don�t crash. Lots of power, easy to adjust for the perfect ride. Someone previously said it was hard to pedal but I sure didn�t experience that. Seems to be a wonderful little bike and I am liking the way it folds up. Will be easy to tuck away, out of sight when I travel! As an older person, I appreciate the battery assistance and this one had a full charge when it arrived. I also appreciate that it isn�t too heavy as I will be lifting it in and out of my truck. Very sturdy frame. Seems to be very well built. And I was able to use a regular bike basket I had for another bike. This will be so helpful for those quick trips to the store.",
               score: 5,
               title: "Perfect for me",
               display_name: "Kasey"
            },
            {
               created_at: "2/15/2021",
               content: "After several months with this bike, I couldn't be happier. Sure, I'd love it to be a bit lighter, but for the price, I am THRILLED. I use it mostly for that last mile commute in NYC- getting the mile from the subway to home or destination. And it's perfect- i dont even need to fold it for the subway, I can carry it through a turnstyle. It's actually easier to carry up or down stairs as a full bike then folded. I can't speak to mileage, since ive never used it for a long commute, or tried to go till the battery was empty. But I've taken 5 trips of about 1.5 miles at full throttle (Im about 210lbs, plus clothes, backpack etc), hills and all, and it does great and the lowest it's ever gotten to was 4 out of 5 bars. It folds up compactly so if space is an issue, this wont take up much. In a NYC apt, that means alot. My biggest complaint was the seat- it's narrow, not well padded and without any gap in the middle as most modern seats have to take pressure off your perineal nerve. It started to get pretty painful- so I bought a new seat on Amazon for $20! That's been great. So, if they would just include a good seat, this bike would be perfect. Ive gotten tons of comments and questions about while riding, it's popular round these parts. I recommend it.",
               score: 4,
               title: "Needs a better seat, but great overall",
               display_name: "Simon"
            },
            {
               created_at: "4/9/2021",
               content: "I have been using this bike for almost 3 months now and it is working great, I use it as my mode of transportation going to work 3 miles every day. I charge it every 3 days, it does not have a shock absorber like the mountain bikes, it would be great if it has a headlight but so far I love this bike.",
               score: 4,
               title: "Commuter",
               display_name: "Megan"
            },
            {
               created_at: "5/15/2021",
               content: "I'm 76 and thought this would be a reasonably cheap way to see whether an ebike would be a good way to get back on 2 wheels again. And so far it fills the bill perfectly. It's well designed, uses good components, and was well put together. It took only 30 minutes to get it unpacked and ready to go. The derailleur got a little bent in shipping but it was easy to fix. It's heavier than I expected, but my previous bike is a titanium Lightspeed so I'm a little spoiled. It folds up into a package small enough to get 2 inside most vehicles. The battery will take me at least 30 miles of fairly flat riding with the medium level of electric assist. Your mileage WILL vary, depending on terrain and your efforts. Charging the battery takes several hours. I found the unsprung seat to be pretty uncomfortable, but Amazon sells some seats that will make your butt happier.",
               score: 4,
               title: "Bargain Bike",
               display_name: "Darren"
            }
        ]
    },
    {
       productId: "gid://shopify/Product/6796272369818",
       reviews: [
            {
               created_at: "6/12/2020",
               content: "I just put 100 miles on mine in two weeks. I use it for commuting from home to work (12 miles round trip), and I can sometimes get to work faster by bike than by car. The best part is that from stop light to stop light, I can accelerate back up to maximum speed quickly and effortlessly. One caveat is that the seat post can not be put down all of the way because it will get too close to the ground, so shorter riders like my wife may have a hard time riding with the seat in a comfortable position. Otherwise, this bike makes cycling fun again for me.",
               score: 4,
               title: "Satisfied",
               display_name: "Jon"
            },
            {
               created_at: "7/30/2020",
               content: "Bike is a heavy-duty,long-range version of the Volador.Weight 48Ibs but has14Ah battery in a longer seat post.Perfect for me at 6'2\",230Ibs.The Volador would have been at its limit, but this one has size and power to spare.20mph on flats throttle only,easy to reach 24 with PAS. On steep 12% grade going up hill to my house,10mph with PAS,6mph with throttle only. I really like the 2.35\" tires-wide enough to float on stand at burning man,skinny enough to fold bike to fit in the back of my 1958 Cessna.This bike is rock solid stable riding hands off at 20mph.No need for suspension the wide tires absorb the cracks in the road.",
               score: 5,
               title: "Powerful enough",
               display_name: "Jeff"
            },
            {
               created_at: "8/19/2020",
               content: "This is my first ebike so I don't have anything to compare to. However, everything has been great so far and I highly recommend. One issue I had was the stem loosened from the fork. The company got back to me within an hour and sent me videos on how to fix it. I was back up and riding in no time. Tools are in the box and do expect some minor setup such as adjusting the brakes when put together. Awesome so far after a couple weeks. I think I got around 20 miles before needing charged which seems reasonable to me.",
               score: 4,
               title: "Assembly required",
               display_name: "Phillip"
            },
            {
               created_at: "10/2/2020",
               content: "We've had our dolphins over a year now and finally got the opportunity to take them to Cades Cove in the Smokey Mts. Bikes preformed extremely well on the 11 mile loop around the cove. They took the 5/6 long steep grades with ease and we had power left over at the end. Excellent product so far!!!!!",
               score: 5,
               title: "We are satisfied",
               display_name: "Larry"
            },
            {
               created_at: "11/16/2020",
               content: "We looked around a lot of E-bikes but found many were just too pricey. Dolphin bikes we received were well packed, front wheel and fork assembly were required along with some basic adjustments. One of ours after riding about 4 hrs had a controller problem. We emailed on a Fri (determined the problem), and the new controller was delivered within 3 days (that following Monday)... so the customer service was excellent. Local bike service was also offered, but their backlog was longer. I chose to have the part sent vs a new bike.. I liked the bike. I also work in electronics mfg, so I know these things happen. The post-sale service was very good. The small controller box was very easily replaced, and the wiring aspect is very common sense when figuring out. Color-coded, and variously sized connectors used so there isn't a concern for mix up. If it looks difficult, just take a picture of the box with the wiring connected.. but really it is an easy 15-minute replacement.",
               score: 4,
               title: "Small issue was quickly resolved",
               display_name: "Matthew"
            },
            {
               created_at: "1/7/2021",
               content: "Bike arrived within one week, properly packaged with no damage. Happy to say I assembled it right out of the box without even needing to read the instructions, which is saying a lot, because I am not good at putting together anything. Worked immediately with no problems and I must say, pretty impressed as I weigh 250+ lbs and it had no problem hitting 21/22 mph w/o making any adjustments to speedometer. So far, so good. Seems durable and rides so super quiet and solid. The breaks work amazingly well as does the Shimano Shifter when using peddle assist. Only comment might be the throttle needing a little bit of torque/pressure to keep fully engaged, which ended up making wrist/thumb & forefinger a little tired/cramped after several miles. Imagine I will get used to that and figure out a way to use it w/o that happening though.",
               score: 4,
               title: "Great right out of the box",
               display_name: "Mike"
            },
            {
               created_at: "4/8/2021",
               content: "We will be trying the Dolphin bikes out this weekend, just finished setting up and charging yesterday. Wish there was instructions for the fenders. Trail and error we got them on. The front light was an issues because I ordered the basket for my bike. We are 64 and 65 we've just started riding bikes again after 15 or more year's. Rode at Jekyll Island GA. I wanted the bikes for Callaway Gardens Ga.",
               score: 5,
               title: "Dolphin",
               display_name: "Kris"
            }
        ]
    },
    {
       productId: "gid://shopify/Product/6796355502234",
       reviews: [
            {
               created_at: "5/26/2020",
               content: "Considering the great price a definite 5 stars. I'm 5'10\" 200lbs and the bike works well. On flat ground it can move without pedaling. But it is obviously designed for pedal support. Going uphill you can balance the gears and the power level to get up easier, faster and conserve battery life. I have a 7 mile commute that is all uphill with a few steep sections (return routes is no problem), this bike gets me up the hills with much less effort. It's fairly simple to assemble if you know how to to mount the fenders,front and rear racks, the rest is pre-built. Only had it a few weeks, but so far strong recommendation. It works",
               score: 5,
               title: "Excellent value",
               display_name: "Pavel"
            },
            {
               created_at: "6/15/2020",
               content: "My wife and I got 2 of the BELUGA about 4 months ago. We have been amazed how much fun these things are. We ride at least 4 times a week now. Aside of some minor adjustments, both bikes have worked wonderfully. I have put well over 450 miles on my bike and not one problem. I added a gel seat, mirrors and lights. Now this bike is ready to travel. They fold very easy and can be thrown in the back of the truck. These are great for camping, day trips and just to have when you are on the road.",
               score: 4,
               title: "It's worth the money",
               display_name: "Steve"
            },
            {
               created_at: "7/20/2020",
               content: "I bought this ebike for the versatility of being able to ride on the beach, offroad and also to do some touring - with the bonus that it folds in half. Overall it works well and I am very happy with the purchase. I can ride it over loose sand, but it is a little squirrely and wouldn't work too well for long distances.. but the medium packed is fun to blast around in and hard packed sand is great, overall it rides excellent on the beach especially near the shoreline. I bought what I hope is a more comfortable seat to replace the one it came with, the one I got to replace has shock absorbers built in and has good reviews. The seat it came with is perfectly comfortable for short or medium length rides, and I found going for more than 20 or 30 minutes was not bad either but could be better. I would say the issue with moderately difficult trails with more obstacles is going uphill. With a mountain bike it is easy be slow and precise and change your speed quickly, but with the motor on this bike it is difficult to adjust speed going up hill, and going over rocks and obstacles becomes more of a challenge. However, going uphill on flatter trails is of course going to be much, much easier.",
               score: 4,
               title: "Happy with purchase",
               display_name: "Jimmy"
            },
            {
               created_at: "10/8/2020",
               content: "Overall this bike works pretty well. Assembly was a little confusing, and I'm very handy, so someone who isn't mechanically inclined may have difficulty. I've had some issues with the disk brakes (too tight upon arrival and still not perfect). The fenders are a little flimsy and some other tiny annoyances. Overall, however, I've had the bike for about a month now, and drive it to work over dirt and sand 5-6 days a week (about 5 miles/day) and the battery lasts for 4 days - took it on the beach yesterday and easily rode 2 miles. I fold it up and shove it in my car every day - don't really take care of it - and it's doing good so far.",
               score: 4,
               title: "Great value compared to others",
               display_name: "Nikki"
            },
            {
               created_at: "12/16/2020",
               content: "This ebike is ridiculous it really catches people's attention when you're on the streets. It's fast and the seat is ok so far everything is ok, the only thing I will suggest is that they should make a more detailed video on how to assemble or build the bike since the current video is too short and is missing some certain parts that you need to know how to do it but for me, it wasn't that big deal I found out how to do it my own.",
               score: 4,
               title: "So far so good",
               display_name: "Mark"
            },
            {
               created_at: "1/9/2021",
               content: "Love the bike and everyone that has tried it was impressed. I would definitely recommend having a bike mechanic build the bike for anyone ordering direct - I had to straighten a bent derailleur hanger and reset both brake calipers and adjust brakes. The Beluga is a great bike. Love all the features and the frame welds are beautiful. great riding bike.",
               score: 4,
               title: "Overall good experience",
               display_name: "Barry"
            },
            {
               created_at: "2/15/2021",
               content: "3 out of 3 Belugas delivered had minor scratches on the paint (fenders and front fork) Also derailleur hangers bent needing straightened on all 3. Suggest better packaging on those areas and perhaps including touch up paint in the box.",
               score: 3,
               title: "Needs better packaging",
               display_name: "James"
            },
            {
               created_at: "4/10/2021",
               content: "One comment. The YouTube video with the installation instructions for the rear parcel shelf goes a little fast and isn�t detailed enough. I had to keep pausing and rewinding. Otherwise solid.",
               score: 4,
               title: "Good product",
               display_name: "Mike"
            }
        ]
    }, 
    {
        productId: "gid://shopify/Product/6796582650010",
        reviews: [
            {
                created_at: '4/15/2021',
                content: 'The experience I had with Dandy Horse was good. I was torn between the MD 750 and HD 750. I used the chat feature on their site, which was actually pretty useful. After about 20 minutes of talking, I was convinced that the HD was plenty of bike for me. I just moved outside of the Scottsdale area and have been addicted to riding for the past 3 weeks.',
                score: 5,
                title: 'Trail Bike',
                display_name: 'Roger'
            },
            {
                created_at: '7/29/2021',
                content: "Ordered one for my son and myself. We make the short trip down to buffalo creek trail from Denver about once a month and this Bike has been crushing it. It's perfect for me because I get to keep up with him while knowing hes enjoying the experience.",
                score: 5,
                title: 'Awesome Service',
                display_name: 'Pete'
            }
        ]
    },
    {
        productId: "gid://shopify/Product/6797021282458",
        reviews: [
            {
                created_at: '5/07/2021',
                content: 'Solid Bike. Wish it was a little faster but overall a smooth ride. Range is good enough for the trails around Salt Lake.',
                score: 4,
                title: 'Good Range, Needs extra speed',
                display_name: 'Martin'
            },
            {
                created_at: '6/17/2021',
                content: "Super happy with my purchase. I am from west Texas where there are plenty of mountains I wanted to explore with an electric bike. I had been in the market for a while and came across Dandy Horse from a friend's recommendation. Their page was super informative and led me to choosing the Warthog from Bikonit. I was sold on the long distance and weight capacity. Both aspects made me feel prepared and safe from running out of battery while carrying enough water. The air suspension also makes the dirt trails far more comfortable. Make sure you get the Dual Battery for extended range!!",
                score: 5,
                title: 'Super Happy with my mountain bike',
                display_name: 'Joe'
            }
        ]
    }
];

// const parseReviews = reviews.map(currentReviews => {
//     const review =  {
//         productId: currentReviews?.reviews[0].product_id,
//         reviews: []
//     }

//     const filteredReviewData = currentReviews.reviews.map(innerReviews => {
//         delete innerReviews.product_id;
//         delete innerReviews.product_title;
//         delete innerReviews.product_url;
//         delete innerReviews.email;
//         delete innerReviews.md_customer_country;
//         delete innerReviews.published;
//         delete innerReviews.product_image_url;
//         delete innerReviews.product_description;
//         delete innerReviews.comment_content;
//         delete innerReviews.comment_public;
//         delete innerReviews.comment_created_at;
//         delete innerReviews.published_image_url;
//         delete innerReviews.unpublished_image_url;
//         delete innerReviews.published_video_url;
//         delete innerReviews.unpublished_video_url;
//         delete innerReviews.cf_Y__X;
//         return innerReviews;
//     })

//     review.reviews = filteredReviewData;
//     return review;
// })

// console.log(parseReviews);

export default reviews;