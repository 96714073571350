import React, { useContext } from 'react'
import { Flex, Text, Icon, Image, Box, useMediaQuery, Badge } from '@chakra-ui/react'
import { ShopContext } from '../context/ShopProvider'
import { Link } from 'react-router-dom'
import { MdShoppingBasket } from 'react-icons/md'
import { RxAvatar } from "react-icons/rx";
import ShopMenu from './shopMenu/ShopMenu'
import NavMenu from './NavMenu'

const NavBar = () => {
    const { openCart, openMenu, checkout, openAuth } = useContext(ShopContext)
    const [isLargerThan1024] = useMediaQuery("(min-width: 1024px)")

    return (
        <Box >
            <Flex
                zIndex="1000"
                backgroundColor="black"
                p="1rem"
                position="fixed"
                top="0" w="100%"
                flexDir="row"
                alignItems="center"
                justifyContent="space-between"
                display={`${isLargerThan1024 ? 'flex' : 'none'}`}>
                <Link to="/">
                    <Image className='slide-in-blurred-left' h='50px' src="https://cdn.shopify.com/s/files/1/0556/6421/4170/files/new_transparent_logo_480x480.png?v=1617307594" />
                </Link> 
                <ShopMenu /> 
                <Link to="/faq">
                    <Text fontSize='1.25rem' fontWeight='bold' color="white">Faq</Text>
                </Link>
                <Link to="/contact">
                    <Text fontSize='1.25rem' fontWeight='bold' color="white">Contact Us</Text>
                </Link> 
                <Link to="/buying-guide">
                    <Text fontSize='1.25rem' fontWeight='bold' color="white">Buying Guide</Text>
                </Link>
                <Link to="/blog">
                    <Text fontSize='1.25rem' fontWeight='bold' color="white">Blog</Text>
                </Link>
                <Box position='relative' mr='20px'>
                    <Icon onClick={() => openCart()} fill="white" cursor="pointer" as={MdShoppingBasket} w={30} h={30}></Icon>
                    <Badge backgroundColor='green.500' color='white' position='absolute' borderRadius="50%">{checkout?.lineItems?.length}</Badge> 
                </Box>
            </Flex>
            <Flex
                zIndex="1000"
                backgroundColor='black'
                p="0.25rem"
                position="fixed"
                top="0"
                w="100%"
                flexDir="row"
                alignItems="center"
                justifyContent="center"
                display={`${!isLargerThan1024 ? 'flex' : 'none'}`}>
                <Link to="/">
                    <Image className='slide-in-blurred-left' h='30px' src="https://cdn.shopify.com/s/files/1/0556/6421/4170/files/new_transparent_logo_480x480.png?v=1617307594" />
                </Link>
            </Flex>
            <Flex 
                zIndex="1000"
                backgroundColor='black'
                p="0.5rem"
                position="fixed"
                top="35px"
                w="100%"
                flexDir="row"
                alignItems="center"
                justifyContent="space-between"
                display={`${!isLargerThan1024 ? 'flex' : 'none'}`}>
                <Box >
                    <NavMenu />
                </Box>
                <Box position='relative' mr='20px'>
                    {/* <Icon onClick={() => openAuth()} color="white" cursor="pointer" as={RxAvatar} w={30} h={30} mr='0.5rem'></Icon> */}
                    <Icon onClick={() => openCart()} fill="white" cursor="pointer" as={MdShoppingBasket} w={30} h={30}></Icon>
                    <Badge backgroundColor='green.500' color='white' position='absolute' borderRadius="50%">{checkout?.lineItems?.length}</Badge> 
                </Box>
            </Flex>
        </Box>
    )
}

export default NavBar
