import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto"
          });
    }, 200) 
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
