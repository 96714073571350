import React from 'react'
import faqData from '../../data/faqData';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Heading, Text } from '@chakra-ui/react';
import parse from 'html-react-parser';

const Faq = () => {
    const scrollToTop = () => setTimeout(() => window.scrollTo(0,0), 300)

    return (
        <Tabs>
            <TabList justifyContent='center' flexDirection={['column', 'row']} position='fixed' backgroundColor='white' width='100%'>
                {faqData.map(faqSection => <Tab _focus={{boxShadow: 'none'}} fontFamily='oswald' onClick={() => scrollToTop()}>{faqSection.sectionTitle}</Tab>)}
            </TabList>
            <TabPanels maxWidth='650px' mx='auto' pt={['8rem', '3rem', '1rem', '1rem']}>
                {faqData.map(faqSection => 
                    <TabPanel>
                        {faqSection.questions.map(faqSectionQuestions => 
                            <>
                                <Heading fontSize='28px' mb='1rem' mt='3rem'>{faqSectionQuestions.question}</Heading>
                                {faqSectionQuestions.answer.map(answer => <Text fontFamily='montserrat' mb='1rem' lineHeight='1.7'>{parse(answer)}</Text>)}
                            </>
                        )}
                    </TabPanel>
                )}
            </TabPanels>
        </Tabs>
    )
}

export default Faq
