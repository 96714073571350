import React, { Component } from 'react'
import Client from 'shopify-buy/index.unoptimized.umd'
import _uniqBy from 'lodash/uniqBy'
import _uniq from 'lodash/uniq'
import axios from 'axios';
import ReactGA from 'react-ga'

const ShopContext = React.createContext();

const client = Client.buildClient({
    domain: process.env.REACT_APP_SHOPIFY_DOMAIN,
    storefrontAccessToken: process.env.REACT_APP_SHOPIFY_API
});

export class ShopProvider extends Component {
    state = {
        product: {}, 
        products: [],
        brands: [],
        collections: [],
        collection: {},
        checkout: {},
        isCartOpen: false,
        isMenuOpen: false,
        isAuthOpen: false,
        blogPosts: [],
        blogPost: {}
    }

    openAuth = async() => this.setState({ isAuthOpen: true })

    closeAuth = async() => this.setState({ isAuthOpen: false })

    componentDidMount() {
        this.fetchAllCollections()
        if (localStorage.checkout_id) {
            this.fetchCheckout(localStorage.checkout_id)
        } else {
            this.createCheckout()
        }
    }

    createCheckout = async () => {
        const checkout = await client.checkout.create()
        localStorage.setItem("checkout_id", checkout.id)
        this.setState({checkout})
    }

    fetchCheckout = async (checkoutId) => {
        const checkout = await client.checkout.fetch(checkoutId)
        if (checkout) {
            this.setState({checkout})
        } else {
            this.createCheckout()
        }
    }

    addItemToCheckout = async (variantId, quantity) => {
        ReactGA.event({
            category: 'conversions',
            action: 'Add Item to Cart'
        })
        
        const lineItemsToAdd = [{
            variantId,
            quantity: parseInt(quantity, 10)
        }]

        const checkout = await client.checkout.addLineItems(this.state.checkout.id, lineItemsToAdd)
        this.setState({checkout})

        this.openCart()
    }

    removeLineItem = async (lineItemIdsToRemove) => {
        const checkout = await client.checkout.removeLineItems(this.state.checkout.id, lineItemIdsToRemove)
        this.setState({checkout})
    }

    parseUniqueBrandNames = (products) => {
        return _uniq(products.map(product => product?.vendor)).reverse();
    }

    parseUniqueProducts = (products) => {
        return _uniqBy(products, 'handle'); 
    }

    fetchAllCollections = async () => {
        const collections = await client.collection.fetchAllWithProducts();
        const products = this.parseUniqueProducts([].concat(...collections.map(collection => collection?.products)));
        const brands = this.parseUniqueBrandNames(products);
        this.setState({collections, products, brands})
    }

    fetchCollection = async (collectionHandle) => {
        const collection = this.state.collections.find(collection => collection.handle === collectionHandle);
        this.setState({collection});
    }

    setCurrentProduct = async (product) => {
        this.setState({product})
    }

    fetchProductReviews = (productId) => {
        return axios.get(`/reviews/${productId}`)
    }

    fetchAllBlogPosts = async () => {
        const response = await axios.get('/blog/posts/all')
        this.setState({blogPosts: response.data});
        return response.data;
    }

    fetchOneBlogPost = async () => {
        const response = await axios.get('/blog/posts/:handle')
        this.setState({blogPost: response});
        return response;
    }

    fetchProductWithMetafields = (productId) => {
        return axios.get(`/api/products/${productId}`);
    }

    closeCart = async () => this.setState({isCartOpen: false})

    openCart = async () => this.setState({isCartOpen: true})

    closeMenu = async () => this.setState({isMenuOpen: false})

    openMenu = async () => this.setState({isMenuOpen: true})

    render() {
        return (
            <ShopContext.Provider 
                value={{
                    ...this.state, 
                    fetchProductWithHandle: this.fetchProductWithHandle,
                    fetchCollection: this.fetchCollection,
                    addItemToCheckout: this.addItemToCheckout,
                    removeLineItem: this.removeLineItem,
                    closeCart: this.closeCart,
                    openCart: this.openCart,
                    closeMenu: this.closeMenu,
                    openMenu: this.openMenu,
                    fetchProductReviews: this.fetchProductReviews,
                    setCurrentProduct: this.setCurrentProduct,
                    fetchProductWithMetafields: this.fetchProductWithMetafields,
                    fetchAllBlogPosts: this.fetchAllBlogPosts,
                    fetchOneBlogPost: this.fetchOneBlogPost,
                    openAuth: this.openAuth,
                    closeAuth: this.closeAuth
                }}
            >
                {this.props.children}
            </ShopContext.Provider>
        )
    }
}

const ShopConsumer = ShopContext.Consumer

export { ShopConsumer, ShopContext };

export default ShopProvider
