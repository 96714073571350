import { Grid, GridItem, Text, Image, Box } from '@chakra-ui/react'
import iconMapper from '../../icons/iconMapper'
import engineIcon from '../../icons/icons/engine.svg'
import React from 'react'

const SpecsGrid = ({metafields}) => {
    return (
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} px={['0.25rem', '0.25rem', '3rem']}>
            {metafields?.map((metafield, index) => !metafield.key.includes('pre_order') && !metafield.key.includes('description_tag') &&
                <GridItem key={index} mb='1.5rem' pr={[0, '1rem']} >
                    <Box h='100%' float='left'><Image src={iconMapper[metafield.key] || engineIcon} width='30px' mr='1rem' pt='0.5rem'></Image></Box>
                    <Text textTransform='capitalize' fontSize='20px'>{metafield.key.split('_').join(' ')}</Text>
                    <Text pl='40px' pr={['0.5rem', '0.5rem', '2rem']} textTransform='capitalize' fontSize='14px' color='gray.600'>{metafield.value}</Text>
                </GridItem>
            )}
        </Grid>
    )
}

export default SpecsGrid
