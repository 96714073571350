import React from 'react'
import { Button, Grid, GridItem, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const Services = ({servicesData}) => {
    return (
        <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]} templateRows="repeat(2, 1fr)" my='4rem'>
            {
                servicesData.map((service, index) => (
                    <GridItem key={service + index} px='2rem' mb='4rem' colSpan={[2, 2, (index === servicesData.length - 1 && index % 2 === 0) ? 2 : 1]} textAlign='center'>
                        <Image mx='auto' w='100px' src={service.imagePath} />
                        <Text fontWeight='bold' fontSize='24px' mb='0.5rem'>{service.title}</Text>
                        <Text maxWidth='400px' mx='auto'>{service.description}</Text>
                        {
                            service.buyGuideData && 
                            <Button mt='20px'>
                                <Link to={service.path}>Learn More</Link>
                            </Button>
                        }
                    </GridItem>
                ))
            }
        </Grid> 
    )
}

export default Services