import React, { useContext, useEffect } from 'react'
import { Box, Text } from '@chakra-ui/react'
import Hero from '../components/hero/Hero'
import Banner from '../components/banner/banner'
import FeaturedCollections from '../components/FeaturedCollections'
import SolutionCollections from '../components/SolutionCollections/SolutionCollections'
import homePageData from '../data/homePageData'
import Assistance from '../components/Assistance'
import Services from '../components/Services'
import { ShopContext } from '../context/ShopProvider'
import _ from 'lodash'


const Home = () => {
    const { collections } = useContext(ShopContext)
    const fitnessCollectionDetails = collections.find(collection => collection.handle === 'beginner-fitness') || {};
    const fitnessProducts = _.isEmpty(fitnessCollectionDetails) ? [] : fitnessCollectionDetails?.products;
    const commuterCollectionDetails = collections.find(collection => collection.handle === 'commuter-ebikes') || {};
    const commuterProducts = _.isEmpty(commuterCollectionDetails) ? [] : commuterCollectionDetails?.products;

    return (
        <Box>
            <Banner />
            <Hero 
                isHomePage={true}
                content={{
                    imgSrc: "/images/outdoor-feature.webp",
                    headline: "Unwrap Adventure: ",
                    subheadline: "Special Holiday Offers on E-Bikes!",
                    srcSet: "/images/mom-girl-md.jpg 1024w, /images/forest.jpg 3750w"
                }} />
            <SolutionCollections
                products={fitnessProducts}
                categoryDetails={{
                    title: fitnessCollectionDetails.title,
                    description: fitnessCollectionDetails.description
                }} />
            <SolutionCollections
                products={commuterProducts}
                categoryDetails={{
                    title: commuterCollectionDetails.title,
                    description: commuterCollectionDetails.description
                }} />
            <FeaturedCollections featuredCollectionData={homePageData.featuredCollectionsData}/>
            <Assistance />
            <Services servicesData={homePageData.servicesData}/>
        </Box>
    )
}

export default Home
