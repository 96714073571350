import React, { useCallback, useContext, useState } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Input,
    VStack,
    Text,
    Link,
    FormControl,
    FormLabel,
    FormErrorMessage,
    List,
    ListItem,
    ListIcon,
} from '@chakra-ui/react';
import { ShopContext } from '../context/ShopProvider';
import axios from 'axios';
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';

const LoginForm = ({ setIsSignUp }) => (
    <VStack spacing={4} align="stretch">
        <FormControl>
            <FormLabel>Username or Email</FormLabel>
            <Input type="email" placeholder="Enter your email" />
        </FormControl>
        <FormControl>
            <FormLabel>Password</FormLabel>
            <Input type="password" placeholder="Enter your password" />
        </FormControl>
        <Button colorScheme="blue" w="100%">
            Login
        </Button>
        <VStack spacing={2} align="stretch" pt={4}>
            <Link color="blue.500" fontSize="sm">
                Forgot Username?
            </Link>
            <Link color="blue.500" fontSize="sm">
                Forgot Password?
            </Link>
            <Text fontSize="sm" pt={2}>
                Don't have an account?{' '}
                <Link color="blue.500" onClick={() => setIsSignUp(true)}>
                    Sign up
                </Link>
            </Text>
        </VStack>
    </VStack>
);

// Password validation rules
const PASSWORD_RULES = [
    { id: 'length', rule: 'At least 8 characters long' },
    { id: 'uppercase', rule: 'Contains at least one uppercase letter' },
    { id: 'lowercase', rule: 'Contains at least one lowercase letter' },
    { id: 'number', rule: 'Contains at least one number' },
    { id: 'special', rule: 'Contains at least one special character' },
];

const SignUpForm = ({ setIsSignUp, closeAuth }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    // Add validation states
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    // Add password validation state
    const [passwordValidation, setPasswordValidation] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false
    });

    // Validation functions
    const validateName = (name, field) => {
        if (name.length < 2) {
            return `${field} must be at least 2 characters long`;
        }
        if (name.length > 20) {
            return `${field} must not exceed 20 characters`;
        }
        if (!/^[a-zA-Z\s]*$/.test(name)) {
            return `${field} must contain only letters`;
        }
        return '';
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Please enter a valid email address';
        }
        return '';
    };

    const validatePassword = (password) => {
        const validations = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };
        setPasswordValidation(validations);
        
        return Object.values(validations).every(v => v) ? '' : 'Password does not meet requirements';
    };

    const validateConfirmPassword = (password, confirmPassword) => {
        return password !== confirmPassword ? 'Passwords do not match' : '';
    };

    // Modified handleInputChange to include validation
    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        let error = '';

        switch (name) {
            case 'firstName':
            case 'lastName':
                error = validateName(value, name === 'firstName' ? 'First name' : 'Last name');
                break;
            case 'email':
                error = validateEmail(value);
                break;
            case 'password':
                error = validatePassword(value);
                break;
            case 'confirmPassword':
                error = validateConfirmPassword(formData.password, value);
                break;
            default:
                break;
        }

        setErrors(prev => ({
            ...prev,
            [name]: error
        }));

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    }, [formData.password]);

    const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/accounts/signup', formData);
            console.log(response.data)
            if (response.data.success) {
                closeAuth();
                // Handle successful signup (maybe show a success toast)
            }
        } catch (error) {
            console.error('Signup error:', error);
            // Handle error (maybe show an error toast)
        }
    };

    // Modified handleSignUp to check all validations
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validate all fields
        const newErrors = {
            firstName: validateName(formData.firstName, 'First name'),
            lastName: validateName(formData.lastName, 'Last name'),
            email: validateEmail(formData.email),
            password: validatePassword(formData.password),
            confirmPassword: validateConfirmPassword(formData.password, formData.confirmPassword)
        };

        setErrors(newErrors);

        // Check if there are any errors
        if (Object.values(newErrors).some(error => error !== '')) {
            return;
        }

        handleSignUp(e);
    };

    return (
        <VStack as="form" onSubmit={handleSubmit} spacing={4} align="stretch">
            <FormControl isInvalid={errors.firstName !== ''}>
                <FormLabel>First Name</FormLabel>
                <Input 
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="Enter your first name" 
                />
                <FormErrorMessage>{errors.firstName}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.lastName !== ''}>
                <FormLabel>Last Name</FormLabel>
                <Input 
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="Enter your last name" 
                />
                <FormErrorMessage>{errors.lastName}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.email !== ''}>
                <FormLabel>Email</FormLabel>
                <Input 
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter your email" 
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.password !== ''}>
                <FormLabel>Password</FormLabel>
                <Input 
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Create a password" 
                />
                <List spacing={1} mt={2} fontSize="sm">
                    {PASSWORD_RULES.map(({ id, rule }) => (
                        <ListItem key={id} color={passwordValidation[id] ? 'green.500' : 'gray.500'}>
                            <ListIcon as={passwordValidation[id] ? CheckIcon : WarningIcon} />
                            {rule}
                        </ListItem>
                    ))}
                </List>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.confirmPassword !== ''}>
                <FormLabel>Confirm Password</FormLabel>
                <Input 
                    name="confirmPassword"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    placeholder="Confirm your password" 
                />
                <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
            </FormControl>

            <Button type="submit" colorScheme="blue" w="100%">
                Create Account
            </Button>

            <Text fontSize="sm" pt={2}>
                Already have an account?{' '}
                <Link color="blue.500" onClick={() => setIsSignUp(false)}>
                    Login
                </Link>
            </Text>
        </VStack>
    );
};

const AuthDrawer = () => {
    const { isAuthOpen, closeAuth } = useContext(ShopContext);
    const [isSignUp, setIsSignUp] = useState(false);



    return (
        <Drawer
            isOpen={isAuthOpen}
            placement="right"
            onClose={closeAuth}
            size="sm"
        >
            <DrawerOverlay>
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        {isSignUp ? 'Create Account' : 'Login'}
                    </DrawerHeader>

                    <DrawerBody>
                        {isSignUp ? (
                            <SignUpForm 
                                setIsSignUp={setIsSignUp}
                                closeAuth={closeAuth}
                            />
                        ) : (
                            <LoginForm setIsSignUp={setIsSignUp} />
                        )}
                    </DrawerBody>

                    <DrawerFooter>
                        {/* Footer content if needed */}
                    </DrawerFooter>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    );
};

export default AuthDrawer; 