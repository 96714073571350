import React from 'react'
import { Tabs, TabList, Tab, TabPanels, TabPanel, Text, Heading, Flex, Box, Grid, GridItem } from '@chakra-ui/react'
import SpecsGrid from './SpecsGrid/SpecsGrid'
// import { Rating } from '@mui/lab';
import moment from 'moment';
import ReviewsModal from './ReviewsModal'
import warrantyReturnsData from '../data/warrantyReturnsData'
import _camelCase from 'lodash/camelCase'
import parse from 'html-react-parser';

const reviewsTabPanel = (reviewsData, reviewsCountText, reviewsScore) => {
    return (
        <TabPanel>
            <>
            <Heading>Customer Reviews</Heading>
            <Flex pb='0.5rem' justifyContent='space-between' mt='1rem'>
                <Box display='flex' flexDirection={['column', 'row']}>
                    {/* <Rating value={reviewsScore} readOnly precision={0.5}/> */}
                    <Text ml='0.5rem'>{`Based on ${reviewsCountText}`}</Text>
                </Box>
                <ReviewsModal />
            </Flex>
            {reviewsData?.reviews?.length ?     
                reviewsData?.reviews?.sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix()).map((review, index) => {
                    return (
                        <Box key={index} my="2rem" border='1px solid' borderColor='gray.200' padding='1rem' borderRadius='0.5rem' color='gray.700'>
                            {/* <Rating value={review.score} readOnly precision={0.5}/> */}
                            <Text fontSize='20px' textTransform='capitalize'>{review.title}</Text>
                            <Text fontSize='14px' color='gray.500' mb='1rem'>{`${review.display_name} on ${moment(review.created_at).format('MMM DD, YYYY')}`}</Text>
                            <Text>{review.content}</Text>
                        </Box>             
                    ) 
                })
            : <Heading textAlign='center' color='gray.600' mt='3rem'>No reviews for this E-Bike yet.</Heading>}
            </>
        </TabPanel>
    )
}

const ProductTabs = ({metafields, reviewsData, reviewsCountText, reviewsScore, vendor}) => {

    return (
        <Tabs isFitted variant="enclosed" my='3rem'>
            <TabList mb="1em" borderColor='blue.500' mx='1rem'>
                <Tab _focus={{boxShadow: 'none'}} fontSize={['16px', '16px', '24px', '26px']} _selected={{ borderBottom: '1px solid blue.500', bg: 'blue.500', color: 'white'}}>Specifications</Tab>
                {/* <Tab _focus={{boxShadow: 'none'}} fontSize={['16px', '16px', '24px', '26px']} _selected={{ borderBottom: '1px solid blue.500', bg: 'blue.500', color: 'white'}}>Reviews</Tab> */}
                <Tab _focus={{boxShadow: 'none'}} fontSize={['16px', '16px', '24px', '26px']} _selected={{ borderBottom: '1px solid blue.500', bg: 'blue.500', color: 'white'}}>Warranty & Returns</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <SpecsGrid metafields={metafields}/>
                </TabPanel>
                {/* {reviewsTabPanel(reviewsData, reviewsCountText, reviewsScore)} */}
                <TabPanel>
                    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']}>
                        <GridItem px='1rem' borderRight={['none', 'none', '3px solid #E2E8F0']}>
                            <Box mb='2rem'>
                                <Heading fontSize='28px' mb='0.5rem'>Warranties</Heading>
                                {warrantyReturnsData[_camelCase(vendor)]?.warrantyPolicy?.map(policyItem => <Text fontFamily='montserrat' mb='1rem' lineHeight='1.7'>{parse(policyItem)}</Text>)}
                            </Box>
                            <Box mb='2rem'>
                                <Heading fontSize='28px' mb='0.5rem'>Warranty Exclusions</Heading>
                                {warrantyReturnsData[_camelCase(vendor)]?.warrantyExclusions?.map(policyItem => <Text fontFamily='montserrat' mb='1rem' lineHeight='1.7'>{parse(policyItem)}</Text>)}
                            </Box>
                        </GridItem>
                        <GridItem px='1rem'>
                            <Heading fontSize='28px' mb='0.5rem'>Returns</Heading>
                            {warrantyReturnsData[_camelCase(vendor)]?.returnPolicy?.map(policyItem => <Text fontFamily='montserrat' mb='1rem' lineHeight='1.7'>{parse(policyItem)}</Text>)}
                        </GridItem>
                    </Grid>
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

export default ProductTabs
