const faqData = [
    {
        sectionTitle: 'General Guidelines',
        questions: [
            {
                question: 'Why use an e-bike instead of a regular bike?', 
                answer: [
                    '<span class="list-title">Faster: </span>You can get to where you need to be faster and with less effort. Most e-bikes can travel at up to 20mph with minimal effort, and some can even travel up to 28mph',
                    '<span class="list-title">Scale Hills: </span>You can climb hills easily and without breaking a sweat.',
                    '<span class="list-title">Safer: </span>E-bikes are generally safer as you can accelerate from a stopped position much faster allowing you to move through an intersection quickly. You can also choose to take different routes through safer streets and not have to worry about being able to finish the ride.',
                    '<span class="list-title">Less Strenuous: </span>E-bikes are also easier on the joints and muscles as less effort is needed to pedal.',
                    '<span class="list-title">Increase Fitness: </span>You can still get plenty of exercise because you can choose how much force will be expelled by the motor. The lower the throttle settings, the more energy you have to produce to keep the bike moving. Studies also show that e-bike owners will generally ride their bike more often that owners of regular bikes.',
                    '<span class="list-title">Environmental: </span>They\'re good for the environment as they are a great alternative to car travel, not to mention cheaper overall when considering cost of fuel, registration, and insurance'
                ]
            },
            {
                question: 'Do I need to get a license to ride an e-bike?',
                answer: [
                    'No, as long as the motor is no more than 750 watts, and it is configured to prevent it from going more than 20mph without pedaling.',
                    'Most states have a minimum age ranging from 14 - 16 years old to operate an e-bike, and helmets may be required by law.',
                    'Although most states model their e-bike laws on provisions of the federal Consumer Product Safety Act, there may be some variations and it is important to check your state\'s e-bike laws before purchasing to make sure you are in compliance.'
                ]
            }, 
            {
                question: 'Is theft a major issue with e-bikes?',
                answer: [
                    'E-bikes are not stolen more frequently that regular bikes, and that is generally because people tend to lock them up better than regular bikes, and a stolen e-bike is not sellable without a charger and battery key. Several e-bikes also come with GPS tracking to make it easier to locate.'
                ]
            },
            {
                question: 'Are e-bikes heavier than  regular bikes?',
                answer: [
                    'E-bikes are generally heavier than their non-motorized counterparts primarily because of the weight of the motor, battery, and more durable frame and tires to withstand higher speeds.',
                    'Although e-bikes are heavier, the motor power more than makes up for the additional weight. Any regular bike feels especially heavy when climbing hills, but with an e-bike you can climb steep hills with very little effort.'
                ]
            }
        ]
    },
    {
        sectionTitle: 'Batteries & Range',
        questions: [
            {
                question: 'What is the range I can get from a single charge?',
                answer: ['Many factors will determine the range like whether you use pedal-assist versus throttle only, the level of pedal-assist, battery capacity, hills, wind, and bicycle payload. Most e-bikes can travel 25 - 50 miles with a relaxed pedaling setting on a single charge, although some can go up 80 miles.']
            },
            {
                question: 'How long does it take to charge the battery?',
                answer: ['Most lithium ion batteries can be fully charged within 3.5 - 6 hours']
            }, 
            {
                question: 'How long is the lifetime of a battery?',
                answer: [
                    'Most e-bike lithium ion batteries will provide at least 500 full charge cycles before the full capacity will be reduced to 80% of the original capacity.',
                    'If you usually use the pedal-assist setting, you can expect to travel 10,000 - 30,000 miles before having to replace a battery.'
                ]
            },
            {
                question: 'How can I charge my e-bike?',
                answer: [
                    'E-bikes will be sold with a power adapter that can be plugged into any standard wall outlet. With some models you may need to remove the battery to plug it in, although many have a socket built into the frame. Most e-bikes also have a battery that is removable and replaceable, while some may be slightly more difficult to remove because of the need to tightly secure the battery during a speedy or bumpy ride.'
                ]
            }
        ]
    },
    {
        sectionTitle: 'Motor, Speed & Performance',
        questions: [
            {
                question: 'What is the difference between a mid-drive and hub-drive motor?',
                answer: [
                    'Mid-drive motors are mounted on the center of the bike frame in between the pedals. They typically offer better range and efficiency compared to hub-drives for the same battery capacity. Although, the extra range is dependent on efficient gear shifting.',
                    'Hub-drive motors are mounted on the center of the rear wheel. They may be a little easier to operate for less experienced cyclist because you will not have to shift as much. It is more difficult to replace a rear tire with a hub-drive motor.'
                ]
            }, 
            {
                question: 'How important is the wattage of the motor?',
                answer: [
                    'Most cyclists will find that they can get the desired performance on e-bikes with 500 watts or less, even when carrying 300lbs or more. Motor design and bike gearing are more important to consider when evaluating performance.',
                    'While a high wattage motor may be more tempting, it should be noted that more watts means a bigger battery. Batteries are typically the most expensive component of an e-bike.'
                ]
            },
            {
                question: 'Is pedaling required with an e-bike?',
                answer: [
                    'Some e-bikes offer a throttle-only option where the e-bike will advance without pedaling. However, when going up steep hills, some light pedaling will be necessary even for throttle-only models.',
                    'All types of e-bikes will be rideable solely by pedaling, just like regular bikes. The motor assist can be turned on and off as necessary.'
                ]
            },
            {
                question: 'What is the difference between the classes of e-bikes?',
                answer: [
                    '<span class="list-title">Class 1: </span>This class has a motor that will only provide power when the rider is pedaling (pedal-assist). It will not provide power when the bike reaches 20mph.',
                    '<span class="list-title">Class 2: </span>This class can be propelled without pedaling (throttle-only) up to 20mph. It can also function like a class 1 e-bike where pedaling is assisted by motor power.',
                    '<span class="list-title">Class 3: </span>This is a pedal-assist class that can reach speeds up to 28mph and will not provide power above that speed.'
                ]
            }
        ]
    },
    {
        sectionTitle: 'Service & Maintenance',
        questions: [
            {
                question: 'How do I maintain my e-bike?',
                answer: [
                    'E-bikes have both mechanical and electrical components. Mechanical components on e-bikes can be maintained similar to how it is done on regular bikes. Basic maintenance like keeping tires properly inflated, lubricating the chain, and replacing worn brake pads may be necessary from time to time.',
                    'Electrical components do not need ongoing maintenance, however, it is recommended that you keep at least some charge on the battery so that it does not get completely depleted. If there is ever any problem with the electrical components, most profesional bike shops can remedy the issue.'
                ]
            },
            {
                question: 'Can my e-bike get wet?',
                answer: [
                    'E-bikes are generally water resistant, but not waterproof. A good rule of thumb is to treat your e-bike like you would other electronics with water/rain. E-bike motors and batteries are sufficiently sealed to survive short term exposure to rain, but may be damaged if wet on a long term basis.',
                    'Do not store your e-bike outside as moisture and creep into electrical components over time. The battery is usually the most susceptible to water damage, so make sure you keep it indoors when not in use.',
                    'Our manufacturers will list the e-bike\'s International Protection code (IP code), which specifies its dust and water protection ratings. Please be sure to evaluate these rating when making a purchasing decision.'
                ]
            },
            {
                question: 'Can I purchase replacement parts?',
                answer: [
                    'Yes. We source manufacturers that can provide replacement part for all the e-bike components.'
                ]
            },
            {
                question: 'What does the warranty cover?',
                answer: [
                    'The warranty will vary based on e-bike manufacturer. We source manufacturers that will provide at least 1 year warranty on all e-bike components. Each warranty policy will be provided on the e-bike description, so please be sure to read and understand.'
                ]
            }
        ]
    }
]

export default faqData;