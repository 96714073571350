import React from 'react'
import { Text, Grid, GridItem, Image} from '@chakra-ui/react'
import motorIcon from '../icons/icons/engine.svg'
import speedometer from '../icons/icons/speedometer.svg'
import distance from '../icons/icons/distance.svg'
import fold from '../icons/icons/fold.svg'
import propulsion from '../icons/icons/propulsion.svg'

const Highlights = ({motorWatts, topSpeed, maxRange, propulsionType, foldable}) => {
    return (
        <Grid templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)',  'repeat(4, 1fr)']} mb='2rem' mt='1rem'>
            <GridItem mb={['1.5rem', 0, 0, '1.5rem', 0]}>
                <Image src={motorIcon} maxWidth='80px' mx='auto'></Image>
                <Text textAlign='center'>{`${motorWatts} Watt Motor`}</Text>
            </GridItem>
            {topSpeed && 
                <GridItem mb={['1.5rem', 0, 0, '1.5rem', 0]}>
                    <Image src={speedometer} maxWidth='80px' mx='auto'></Image>
                    <Text textAlign='center'>{`${topSpeed}mph Top Speed`}</Text>
                </GridItem>
            }
            <GridItem>
                <Image src={distance} maxWidth='80px' mx='auto'></Image>
                <Text textAlign='center'>{`${maxRange}mi Range Est.`}</Text>
            </GridItem>
            {foldable || propulsionType && 
                <GridItem>
                    <Image src={foldable ? fold : propulsion } maxWidth='80px' mx='auto'></Image>
                    <Text textTransform='capitalize' textAlign='center'>{foldable ? 'Foldable Frame' : propulsionType}</Text>
                </GridItem>
            }
        </Grid>
    )
}

export default Highlights
